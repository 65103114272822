import { useState, useEffect } from "react";
import { useAppContext } from "Context/AppProvider";
import ModalBackdrop from "Components/ModalBackdrop";
import RequestForm from "Components/RequestForm";
import SettingsConditional from "Components/SettingsConditional";
import "Assets/styles/scrollable-modal.scss";
import "./RequestModal.scss";
import { ModalTitle } from "./Typography/Heading";
import useLearnerWorkContext from "Hooks/useLearnerWorkContext";
import useExternalUser from "Hooks/useExternalUser";
import Spinner from "./Spinner";
import WorkContextErrors from "./WorkContext/WorkContextErrors";
import { Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "constants/toast";
import { ArCreateFail } from "Components/ToastMessages";
import { DateTime } from "luxon";
import { isEmpty } from "util/core";

const getTimeInfo = startTime => {
  if (!startTime) return { formattedTime: null, countdownText: null, fullTimeText: null };
  const now = DateTime.now();
  const startDateTime = DateTime.fromJSDate(startTime);

  const formattedTime = startDateTime.toFormat("t");
  const countdownText = startDateTime.toRelative();

  const dayDifference = startDateTime.startOf("day").diff(now.startOf("day"), "days").days;

  const dayLabel =
    dayDifference === 0
      ? "today"
      : dayDifference === 1
      ? "tomorrow"
      : `on ${startDateTime.toFormat("cccc'")}`;

  const fullTimeText = `${formattedTime} ${dayLabel}`;

  return { formattedTime, countdownText, fullTimeText };
};

const RequestModal = ({
  queueChannel,
  hide,
  onRequestSubmit,
  queueSchedule: qs,
  isAIOnly,
  inPrep = false,
}) => {
  const {
    currentUser,
    settingsHandler: { settings },
  } = useAppContext();

  const {
    workContext,
    loading: workContextLoading,
    findActivityByUuid,
    errors: workContextErrors,
  } = useLearnerWorkContext();

  const { externalUser, isLoading } = useExternalUser(currentUser, "Discord");

  const [loading, setLoading] = useState(true);
  const [prepAvailable, setPrepAvailable] = useState(false);

  useEffect(() => {
    const fetchExternalUser = async () => {
      if (!inPrep) return setLoading(false);
      if (isLoading) return setLoading(true);

      if (externalUser && !isEmpty(externalUser)) {
        setPrepAvailable(true);
      } else {
        setPrepAvailable(false);
      }

      setLoading(false);
    };

    fetchExternalUser();
  }, [inPrep, externalUser, isLoading]);

  const { formattedTime, countdownText, fullTimeText } = getTimeInfo(qs?.startTime);
  const queueScheduleForcedClosed = qs?.forceScheduleClose;

  const LarryOnlyARMessage = () => {
    if (inPrep) {
      return (
        <div>
          Try out our AI tutor Larry AI for assistance with your prep work. During bootcamp, you'll
          have access to a team of available mentors as well.
        </div>
      );
    }
    if (queueScheduleForcedClosed) {
      return (
        <div>
          The mentor queue is currently closed, so only Larry AI bot will answer this request. Your
          peers are still able to participate in the conversation.
        </div>
      );
    }

    return (
      <div>
        The mentor queue is closed and will reopen {countdownText} at {fullTimeText}
        , so only Larry AI bot will answer this request. <br />
        Your peers are still able to participate in the conversation.
      </div>
    );
  };

  const requestAssistance = formValues => {
    queueChannel
      .requestAssistance({
        activity: findActivityByUuid(formValues.activityId),
        ...formValues,
        route: !(isAIOnly || inPrep),
        isAIOnly: isAIOnly || inPrep,
      })
      .catch(() => toast.error(<ArCreateFail />, TOAST_OPTIONS.error));
    onRequestSubmit();
    hide();
  };

  const PrepUnavailableMessage = () => (
    <Alert variant="info">
      <Alert.Heading as="h6" className="d-flex align-items-center">
        <i className="fa fa-exclamation-triangle me-4" aria-hidden="true"></i>
        Larry AI Unavailable
      </Alert.Heading>
      Larry is only available to students who have confirmed their attendance to an upcoming
      program. If you are an upcoming student, make sure you link your Discord (see the activity
      "Setting Up and Using Discord")
    </Alert>
  );

  const LoadingIndicator = ({ includeContextLoadingMessage = false } = {}) => (
    <div className="d-flex justify-content-center align-items-center m-2">
      <Spinner />
      {includeContextLoadingMessage && <div>&nbsp;Loading context information...</div>}
    </div>
  );

  const RequestFormOrMessage = () => {
    if (loading) return LoadingIndicator();
    if (!prepAvailable && inPrep) return PrepUnavailableMessage();
    if (workContextLoading) return LoadingIndicator({ includeContextLoadingMessage: true });

    return (
      <>
        {!!qs?.requestBeforeQueueEnabled && (
          <Alert variant="warning">
            Lighthouse Labs mentors will begin responding to assistance requests at {formattedTime}{" "}
            [{qs.timezone}] (in about {countdownText}).
          </Alert>
        )}
        {(inPrep || isAIOnly) && <Alert variant="info">{LarryOnlyARMessage()}</Alert>}
        <WorkContextErrors errors={workContextErrors} />
        <RequestForm
          workContext={workContext}
          onSubmit={requestAssistance}
          hide={hide}
          currentUser={currentUser}
          chatAssistanceEnabled={!!settings?.features?.chatAssistance}
          isAIOnly={isAIOnly}
          showEnhancedRequestPrompt={currentUser.featureFlags?.requestCodePrompt}
          showOptionalRequestInputs={currentUser.featureFlags?.optionalRequestInputs}
        />
      </>
    );
  };

  return (
    <ModalBackdrop blur center>
      <div className="modal-dialog dialog-wide">
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>
              <SettingsConditional flag={SettingsConditional.REQUEST_MODAL_TITLE} />
            </ModalTitle>
            <button type="button" className="close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            {inPrep ? (
              RequestFormOrMessage()
            ) : (
              <SettingsConditional
                flag={SettingsConditional.DISCORD_AUTH_REQUIRED}
                platform="Discord"
              >
                {RequestFormOrMessage()}
              </SettingsConditional>
            )}
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default RequestModal;
