import { startCase } from "lodash";
import { featureDescriptions } from "constants/settingsDescriptions";

const FeatureSettingsForm = ({ onSubmit, featureSettings, setFeatureSettings }) => {
  const input = (name, value) => {
    switch (typeof value) {
      case "number":
        return (
          <input
            className="form-control"
            type="number"
            step="1"
            name={name}
            onChange={e =>
              setFeatureSettings(prevSettings => ({
                ...prevSettings,
                [name]: isNaN(e.target.value) ? "" : parseFloat(e.target.value),
              }))
            }
            value={value || 0}
            min={0}
            max={1000}
          />
        );
      case "boolean":
        return (
          <select
            className="form-control"
            onChange={e =>
              setFeatureSettings(prevSettings => ({
                ...prevSettings,
                [name]: e.target.value === "true",
              }))
            }
            value={value.toString()}
            name={name}
            style={{ width: "127px" }}
          >
            <option value={true}>True</option>
            <option value={false}>False</option>
          </select>
        );
      default:
        return (
          <input
            className="form-control"
            type="text"
            name={name}
            onBlur={e =>
              setFeatureSettings(prevSettings => ({
                ...prevSettings,
                [name]: e.target.value,
              }))
            }
            value={value || ""}
          />
        );
    }
  };

  const options = () => {
    if (!featureSettings) return <></>;

    return Object.keys(featureDescriptions)
      .filter(name => featureDescriptions[name].editable !== false)
      .map(name => {
        return (
          <div className="option" key={name}>
            <div className="description">
              <label>{startCase(name)}</label>
              <small>{featureDescriptions[name]["description"]}</small>
            </div>
            {input(name, featureSettings[name])}
          </div>
        );
      });
  };

  return (
    <div>
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <h2>Feature Settings</h2>
      </div>
      <div>
        <form onSubmit={onSubmit} className="queue-weights">
          {options()}
          <div className="option">
            <div className="description"></div>
            <input className="btn btn-warning" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeatureSettingsForm;
