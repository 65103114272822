import { useAppContext } from "Context/AppProvider";
import PendingTaskRequest from "./PendingTaskRequest";
import ListGroup from "Components/ListGroup";
import PendingEvaluation from "./PendingEvaluation";

const OffDutyNote = () => (
  <div className="alert alert-danger off-duty-alert">
    <strong>You must be on duty to be assigned tasks!</strong>
  </div>
);

const OpenSecondaryTasksList = ({ tasks, currentUser }) => {
  const {
    settingsHandler: { settings },
  } = useAppContext();

  const taskQueueMax = settings?.features?.secondaryTasksQueueMax || 2;

  const sortedTasks = tasks.sort((a, b) => {
    const priorityA =
      a.assistanceRequest.priority !== null ? a.assistanceRequest.priority : Infinity;
    const priorityB =
      b.assistanceRequest.priority !== null ? b.assistanceRequest.priority : Infinity;
    return priorityA - priorityB;
  });

  const renderAssistanceRequests = () => {
    return sortedTasks
      .slice(0, taskQueueMax)
      .map(task =>
        task.assistanceRequest.type === "TaskRequest" ? (
          <PendingTaskRequest key={task.id} task={task} />
        ) : (
          <PendingEvaluation key={task.id} assistanceRequest={task.assistanceRequest} />
        )
      );
  };

  return (
    <ListGroup
      icon={tasks.length}
      title="Assigned Secondary Tasks"
      className="assigned-task-requests"
      columns={currentUser.onDuty && tasks.length !== 0 ? 2 : 1}
    >
      {currentUser.onDuty ? renderAssistanceRequests() : OffDutyNote()}
    </ListGroup>
  );
};

export default OpenSecondaryTasksList;
