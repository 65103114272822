import { useState, useEffect } from "react";
import { ModalTitle } from "./Typography/Heading";
import "./Typography/Typography.scss";

function getNowInSeconds() {
  return Math.floor(+new Date() / 1000);
}

// calculate the time to being set off duty based on data provided
// by the server. Subtract an additional 2 seconds to account for the
// setInterval starting a second later and another second off so that
// the countdown reaches 0 instead of 1 before the user is taking off duty
function getTimeLeft(idleAtSeconds) {
  return idleAtSeconds - getNowInSeconds() - 2;
}

export default function IdleMentorModal({ forcingOffDutyAt, hide }) {
  const idleAtSeconds = Math.ceil(+new Date(forcingOffDutyAt) / 1000);

  const [timeLeft, setTimeLeft] = useState(getTimeLeft(idleAtSeconds));

  // countdown to off-duty
  useEffect(() => {
    const timeLeftInterval = setInterval(() => {
      setTimeLeft(getTimeLeft(idleAtSeconds));
    }, 1000);

    // unset interval on unmount
    return () => clearInterval(timeLeftInterval);
  }, [timeLeft]);

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <ModalTitle>You&apos;ve been flagged as idle</ModalTitle>
              <button type="button" className="close" onClick={hide}>
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Close</span>
              </button>
            </div>

            <div className="modal-idle-copy">
              <label>
                We&apos;ve detected that you have been idle for a while. You will be taken off duty
                soon so that active mentors can take on your Assigned Requests.
              </label>

              <label className="bold-label">
                To dismiss this modal and mark yourself as active, simply press a key or interact
                with this page in any way.
              </label>

              <label className="bold-label">
                Otherwise, you will be marked as off duty
                {timeLeft > 0 ? ` in ${timeLeft} seconds.` : " now."}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
