import { useNavigate } from "react-router-dom";
import ResponsiveLabel from "Components/ResponsiveLabel";
import HoverBadge from "Components/HelpDashboard/HoverBadge";
import Nav from "react-bootstrap/Nav";

const FeedbackButton = ({ count }) => {
  const navigate = useNavigate();

  const toFeedbacks = () => {
    navigate("/feedbacks");
  };
  return (
    <Nav.Link onClick={toFeedbacks}>
      <i className="fa fa-fw fa-comment" />
      {count !== 0 && <HoverBadge pill bg="danger" title={count} />}
      <ResponsiveLabel sizes={["small", "medium", "large"]}>Feedback</ResponsiveLabel>
    </Nav.Link>
  );
};

export default FeedbackButton;
