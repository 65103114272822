import { useEffect } from "react";
import { useAppContext } from "Context/AppProvider";
import FeedbackButton from "Components/FeedbackButton";
import MentorNavigation from "Components/MentorNavigation";
import UserOptions from "Components/Navigation/UserOptions";
import AdminOptions from "Components/Navigation/AdminOptions";
import MentorStatusButton from "Components/MentorStatusButton";
import AssistanceStatusButton from "Components/AssistanceStatusButton";
import Spinner from "Components/Spinner";
import Button from "react-bootstrap/Button";
import requests from "Requests";
import "./Launch.scss";

const Launch = ({ activity, token, nav }) => {
  const {
    queueChannel: { pendingFeedbackCount, rejected },
    currentUser,
    teacherHandler: { teachers },
  } = useAppContext();

  useEffect(() => {
    if (token) {
      requests.postIdToken(token).catch(err => {
        console.log(err);
      });
    }
  }, [token]);

  return (
    <div className={`embedded ${nav ? "nav" : ""}`}>
      {currentUser.isPresent ? (
        <>
          {currentUser.isAdmin && <AdminOptions />}
          {currentUser.isTeacher && <UserOptions currentUser={currentUser} />}
          {!currentUser.inPrep && <MentorStatusButton nav={nav} teachers={teachers} />}
          {currentUser.isTeacher && <MentorNavigation nav={nav} user={currentUser} />}
          {currentUser.isStudent && (
            <ul className="d-flex m-0 p-0">
              <FeedbackButton nav={nav} user={currentUser} count={pendingFeedbackCount} />
              <AssistanceStatusButton nav={nav} activity={activity} />
            </ul>
          )}
        </>
      ) : (
        <Button variant={`outline-${rejected ? "danger" : "warning"}`}>
          {rejected ? <i className="fa fa-fw fa-xmark" /> : <Spinner />}
        </Button>
      )}
    </div>
  );
};

export default Launch;
