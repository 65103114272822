import ModalBackdrop from "Components/ModalBackdrop";
import { ModalTitle } from "./Typography/Heading";
import Button from "Components/Buttons/AssistanceButtons";
import Spinner from "Components/Spinner";
import Markdown from "Components/Markdown";
import { startCase } from "lodash";
import requests from "Requests";
import { useEffect, useState } from "react";

const ClaimContextModal = ({ assistanceRequest, cancelClaim, setShowModal }) => {
  const [loaded, setLoaded] = useState(false);
  const [instructions, setInstructions] = useState(null);
  const [showFullInstructions, setShowFullInstructions] = useState(false);
  const { deployment, requestor, request, tags } = assistanceRequest;

  const student = {
    profileLink: requestor?.profileLink,
    pronoun: requestor?.pronoun || "they",
    name: requestor?.fullName,
    location: requestor?.info?.location,
    week: requestor?.info?.week,
    program: requestor?.info?.program_name,
    discord: requestor?.socials?.discord,
  };

  const activity = {
    name: request?.resourceName,
    type: request?.resourceType,
    link: request?.resourceLink,
    uuid: request?.resourceUuid,
  };

  useEffect(() => {
    const abortController = new AbortController();
    const getActivityDetails = () => {
      return requests
        .getActivityDetails(
          {
            deployment_id: deployment,
            uuid: activity.uuid,
            type: activity.type,
          },
          { signal: abortController.signal }
        )
        .then(res => setInstructions(res.instructions))
        .catch(() => {
          if (!abortController.signal.aborted) setInstructions("Could not load instructions.");
        })
        .finally(() => setLoaded(true));
    };

    getActivityDetails();

    return () => abortController.abort();
  }, [deployment, activity.uuid, activity.type]);

  const toggleInstructions = () => setShowFullInstructions(!showFullInstructions);

  const accommodationTags = tags.filter(tag => tag.settings.category === "accommodation");

  const cancel = () => {
    setShowModal(false);
    cancelClaim();
  };

  return (
    <ModalBackdrop>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>Assistance Request Context</ModalTitle>
          </div>
          <div className="modal-body">
            <p>To prepare for this assistance request, please review the following material:</p>
            <h5>Request Information</h5>
            <ul>
              <li>
                <a href={student.profileLink} target="_blank" rel="noreferrer">
                  {student.name}
                </a>{" "}
                ({student.location}, <i className="fa fab fa-discord" /> {student.discord}) is in{" "}
                {student.week} of the {student.program} bootcamp
              </li>
              <li>
                {startCase(student.pronoun)} are looking for help with the{" "}
                <a href={activity.link} target="_blank" rel="noreferrer">
                  {activity.name}
                </a>{" "}
                {activity.type}
              </li>
              {accommodationTags.length > 0 &&
                accommodationTags.map(tag => (
                  <li key={tag.name}>
                    <strong>Accommodations Note: </strong> {tag.queueNote}
                  </li>
                ))}
            </ul>
            <h5>Activity Instructions</h5>
            {loaded ? (
              <div className="m-3">
                <Markdown>
                  {showFullInstructions ? instructions : `${instructions.substring(0, 200)}...`}
                </Markdown>
                <div className="d-flex justify-content-end">
                  <Button.Light
                    text={showFullInstructions ? "Show Less" : "Show More"}
                    onClick={toggleInstructions}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center m-2">
                <Spinner />
                &nbsp;Getting activity instructions...
              </div>
            )}
            <p>
              The student will not be notified of your presence in this assistance until you exit
              this modal and click the "Start" button.
            </p>
            <div className="step-button-container">
              <Button.Main type="secondary" onClick={cancel} text="Requeue" data-dismiss="modal" />
              <Button.Info
                onClick={() => setShowModal(false)}
                text="I have reviewed this material and I'm ready to assist!"
                data-dismiss="modal"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default ClaimContextModal;
