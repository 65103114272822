import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import PaddedContent from "Layouts/PaddedContent";
import { useAppContext } from "Context/AppProvider";
import StudentInfo from "Components/StudentInfo";
import Tabs from "Components/Tabs";
import Assistances from "./tabs/Assistances";
import Tags from "./tabs/Tags";
import NotFound from "Components/NotFound";
import ManualAssistanceModal from "Components/ManualAssistanceModal";
import "./Show.scss";

const tabs = {
  Assistances: { key: "assistances" },
  Tags: { key: "tags", admin: true },
};

const Tab = ({ tab, user, applyTag, removeTag }) => {
  switch (tab) {
    case tabs.Assistances.key:
      return <Assistances assistances={user.assistances} />;
    case tabs.Tags.key:
      return <Tags user={user} applyTag={applyTag} removeTag={removeTag} />;
  }
};

const Show = () => {
  const {
    currentUser,
    studentHandler: { getStudent, getAssistances, applyTag, removeTag },
  } = useAppContext();
  const { uid, tab: initialTab } = useParams();
  const tab = initialTab || tabs.Assistances.key;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const user = getStudent(uid);

  useEffect(() => {
    if (!initialTab) navigate(`/learners/${uid}/${tabs.Assistances.key}`);
  }, [navigate, initialTab, uid]);

  const openModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    getAssistances(uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onChangeTab = tab => {
    navigate(`/learners/${uid}/${tab}`);
  };

  const keys = Object.values(tabs)
    .filter(tab => !tab.admin || currentUser.isAdmin)
    .map(tab => tab.key);

  if (user.error) {
    return <NotFound />;
  }

  return (
    <PaddedContent className="learner-page">
      <div className="navigation">
        <Link className="btn home-btn" to="/">
          <i className="fa fa-fw fa-house" />
        </Link>
      </div>
      <div className="learner">
        <StudentInfo student={user} when={user.lastAssistedAt} showDetails />
        <div className="d-flex justify-content-end my-2">
          <button className="btn btn-sm btn-light btn-main" onClick={openModal}>
            Log an Assistance
          </button>
        </div>
        <Tabs tab={tab} tabs={keys} onChangeTab={onChangeTab}>
          <Tab tab={tab} user={user} applyTag={applyTag} removeTag={removeTag} />
        </Tabs>
      </div>
      {showModal && <ManualAssistanceModal hide={() => setShowModal(false)} student={user} />}
    </PaddedContent>
  );
};

export default Show;
