import { ModalTitle } from "./Typography/Heading";

export default function ForcedOffDutyModal({ hide }) {
  return (
    <>
      <div className="modal-backdrop fade show"></div>

      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <ModalTitle>You are now off duty</ModalTitle>
              <button type="button" className="close" onClick={hide}>
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Close</span>
              </button>
            </div>

            <div className="modal-idle-copy">
              You&apos;ve been marked as off duty due to inactivity.
            </div>

            <div className="modal-footer modal-idle-footer d-flex">
              <button onClick={hide} className="btn btn-primary" type="button">
                Got it.
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
