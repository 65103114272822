import useUser from "Hooks/useUser";
import useTags from "Hooks/useTags";
import PaddedContent from "Layouts/PaddedContent";
import { useParams, Link } from "react-router-dom";
import { startCase } from "lodash";
import Table from "react-bootstrap/Table";
import ToggleButton from "Components/ToggleButton";
import NotFound from "Components/NotFound";
import Splash from "Components/Splash";

const Show = () => {
  const { id } = useParams();
  const { user, applyTag, removeTag } = useUser(id);
  const { tags } = useTags();

  if (Object.keys(user).length < 1) {
    return <Splash />;
  } else if (user.error) {
    if (user.error.response && user.error.response.status === 404) return <NotFound />;

    return <p>{user.error.toString()}</p>;
  }

  const categorizedTags = {};

  tags.forEach(tag => {
    const category = tag.settings?.category || "Uncategorized";

    if (!categorizedTags[category]) categorizedTags[category] = [];
    categorizedTags[category].push(tag);
  });

  const tagsByCategory = Object.keys(categorizedTags).map(category => (
    <div key={category}>
      <h5>{startCase(category)}</h5>
      {categorizedTags[category].map(tag => (
        <ToggleButton
          key={tag.id}
          name={tag.name}
          isActive={user.tags.map(t => t.id).includes(tag.id)}
          activate={() => applyTag(tag)}
          deactivate={() => removeTag(tag)}
        />
      ))}
    </div>
  ));

  return (
    <PaddedContent>
      <Link to="/users">
        <i className="fa fa-fw fa-arrow-left" />
      </Link>
      {Object.keys(user).length > 0 && (
        <>
          <h3>User Info:</h3>
          <Table bordered>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{user.fullName}</td>
              </tr>
              <tr>
                <td>Role</td>
                <td>{user.type}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{user.socials.email}</td>
              </tr>
              <tr>
                <td>Github</td>
                <td>{user.socials.github}</td>
              </tr>
            </tbody>
          </Table>
          <h3>User Tags:</h3>
          {tagsByCategory}
        </>
      )}
    </PaddedContent>
  );
};

export default Show;
