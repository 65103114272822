import { useState } from "react";
import QueueItem from "Components/QueueItem";
import RequestDetails from "Components/RequestDetails";
import { useAppContext } from "Context/AppProvider";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "constants/toast";
import SkipRequestModal from "Components/SkipRequestModal";
import Requests from "Requests";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import TaskMasterInfo from "Components/TaskMasterInfo";

const PendingTaskRequest = ({ task }) => {
  const {
    queueChannel: { claimAssistance },
    tasksHandler,
    settingsHandler: { settings },
  } = useAppContext();

  const taskRequest = task.assistanceRequest;
  const taskActivityTitle = taskRequest.request.resourceName || "Mentor Task Resource";

  const [disabled, setDisabled] = useState(false);
  const [showSkipRequestModal, setShowSkipRequestModal] = useState(false);
  const mentorSkip = settings?.featureFlags?.mentorSkip;
  const handleSkipAR = () => setShowSkipRequestModal(true);

  const taskCategory =
    taskRequest.request.taskType === "Other" || !taskRequest.request.taskType
      ? "Mentor Task"
      : taskRequest.request.taskType;

  const handleClaimAR = () => {
    setDisabled(true);
    claimAssistance(taskRequest)
      .then(() => tasksHandler.claimAssistance(taskRequest.id))
      .catch(err => {
        setDisabled(false);
        toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
      });
  };

  const skipRequest = (reason, notes) => {
    setDisabled(true);

    const queueTask = tasksHandler
      .allOpenSecondaryTasks()
      .find(task => task.assistanceRequest.id === taskRequest.id);

    Requests.skipRequest(queueTask.id, reason, notes)
      .then(() => tasksHandler.skipRequest(queueTask.id))
      .catch(err => {
        setDisabled(false);
        toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
      })
      .finally(setDisabled(false));
  };

  const isSinglePendingRequest =
    tasksHandler
      .pendingAssistanceRequests()
      .filter(ar =>
        tasksHandler
          .allOpenSecondaryTasks()
          .some(task => task.assistanceRequest.id === ar.id && !task.skippedAt)
      ).length === 1;

  const hoverText = (
    <Tooltip id="tooltip">
      <strong>Cannot skip</strong> when you have only one assigned task remaining.
    </Tooltip>
  );

  const buttonClassName = `btn btn-sm ${
    isSinglePendingRequest ? "btn-secondary" : "btn-light btn-main"
  }`;

  const skipButton = (
    <button
      key="skip"
      className={buttonClassName}
      onClick={isSinglePendingRequest || disabled ? null : handleSkipAR}
      disabled={isSinglePendingRequest}
      style={{ pointerEvents: isSinglePendingRequest ? "none" : "auto" }}
    >
      Skip
    </button>
  );

  const renderSkipButton = () =>
    isSinglePendingRequest ? (
      <OverlayTrigger placement="left" overlay={hoverText}>
        <span>{skipButton}</span>
      </OverlayTrigger>
    ) : (
      skipButton
    );

  return (
    <>
      <QueueItem label="Task" type="Request" disabled={disabled}>
        <TaskMasterInfo
          tags={taskRequest.tags}
          when={taskRequest.createdAt}
          taskType={taskRequest.request.taskType}
        />
        <div className="blurb">
          <RequestDetails
            categoryName={taskCategory}
            reason={taskRequest.request.reason}
            activityTitle={taskActivityTitle}
            activityUrl={taskRequest.request.link}
          />
        </div>
        <div className="actions">
          {mentorSkip && renderSkipButton()}
          <button
            key="claim"
            className="btn btn-sm btn-warning btn-main"
            onClick={handleClaimAR}
            disabled={disabled}
          >
            Start
          </button>
        </div>
      </QueueItem>
      {showSkipRequestModal && (
        <SkipRequestModal
          requestType="task"
          skip={skipRequest}
          hide={() => setShowSkipRequestModal(false)}
        ></SkipRequestModal>
      )}
    </>
  );
};

export default PendingTaskRequest;
