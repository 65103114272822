import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import InputField from "Components/Form/InputField";
import StepIndicator from "Components/Form/StepIndicator";
import ErrorList from "Components/Form/ErrorList";
import { StarRatingInput } from "Components/Form/CustomInputs";
import "Components/Form/Form.scss";
import "./AssistanceReviewForm/AssistanceReviewForm.scss";
import StepProvider, { useStepContext, Step } from "Context/StepProvider";

const TaskReviewField = props => <InputField prefix="task-review" {...props} />;

const TaskReviewForm = ({ onSubmit, assistanceRequest, answers, taskType }) => {
  const { count: stepCount, step, next, back } = useStepContext();
  const previousAnswers = answers[assistanceRequest?.id];
  const larryReview = taskType === "Larry Review";
  const curriculumReview = taskType === "Curriculum Review";
  const aifReview = taskType === "AIF Review";

  const initialValues = {
    requestId: assistanceRequest?.id,
    rating: previousAnswers?.rating || 0,
    notes: previousAnswers?.notes || "",
    llmBotRating: larryReview ? previousAnswers?.llmBotRating || 0 : undefined,
    timeSpentInMinutes: previousAnswers?.timeSpentInMinutes || 0,
    taskIssues: previousAnswers?.taskIssues || [],
  };

  const assistanceSchema = Yup.object().shape({
    timeSpentInMinutes: Yup.number()
      .min(0, "You cannot spend negative minutes on a task")
      .max(4000, "It is not possible for this task to have taken that long"),
    rating: Yup.number()
      .required("Please select a rating")
      .min(1, "Please select a rating")
      .max(4, "Invalid rating"),
    notes: larryReview ? Yup.string().required("Please provide your review here") : Yup.string(),
    llmBotRating: larryReview
      ? Yup.number()
          .required("Please rate Larry's recommendation")
          .min(1, "Please select a rating")
          .max(5, "Invalid rating")
      : undefined,
    taskIssues: Yup.array(),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    if (confirm("Are you sure you want to submit this review?")) {
      onSubmit(values);
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={assistanceSchema}
      onSubmit={handleSubmit}
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
        validateForm,
        setFieldTouched,
      }) => {
        const stepValidation = fieldNames => () =>
          validateForm().then(errors => {
            fieldNames.forEach(name => setFieldTouched(name));
            return fieldNames.some(name => errors[name]);
          });
        const handleCheckboxChange = (fieldName, value) => {
          const currentValues = values[fieldName] || [];
          const valueIndex = currentValues.indexOf(value);

          if (valueIndex !== -1) {
            const updatedValues = [
              ...currentValues.slice(0, valueIndex),
              ...currentValues.slice(valueIndex + 1),
            ];
            setFieldValue(fieldName, updatedValues);
          } else {
            setFieldValue(fieldName, [...currentValues, value]);
          }
        };
        return (
          <Form>
            <div className="step-indicator-container">
              <StepIndicator step={step} stepCount={stepCount} className="step-indicator" />
            </div>
            <ErrorList touched={touched} errors={errors} />
            <Step beforeNext={stepValidation(["llmBotRating", "rating", "timeSpentInMinutes"])}>
              <TaskReviewField
                name="timeSpentInMinutes"
                label="How long did you spend actively working on this task (in minutes)?"
                as="input"
                type="number"
              />
              {larryReview && (
                <>
                  <TaskReviewField
                    name="rating"
                    label="How would you rate this conversation overall?"
                    as="select"
                  >
                    <option value="0" disabled>
                      Please select an option...
                    </option>
                    <option value="4">It was very useful, accurate, and/or helpful!</option>
                    <option value="3">It was good, and Larry was eventually able to help</option>
                    <option value="2">Larry was a bit confusing, but some of it was helpful</option>
                    <option value="1">It was misleading, inaccurate, and/or unhelpful</option>
                  </TaskReviewField>
                  <TaskReviewField
                    name="llmBotRating"
                    label="How would you rate Larry's initial recommendation?"
                    info="A 5-star rating fits to the context, is factually accurate, and represents best practices."
                    component={StarRatingInput}
                  />
                </>
              )}
              {curriculumReview && (
                <TaskReviewField
                  name="rating"
                  label="Did you approve or reject this PR?"
                  as="select"
                >
                  <option value="0" disabled>
                    Please select an option...
                  </option>
                  <option value="4">Approve</option>
                  <option value="1">Reject</option>
                </TaskReviewField>
              )}
              {aifReview && (
                <TaskReviewField
                  name="rating"
                  label="Is this helpful feedback for the student at their stage in their journey?"
                  as="select"
                >
                  <option value="0" disabled>
                    Please select an option...
                  </option>
                  <option value="4">Yes</option>
                  <option value="1">No</option>
                </TaskReviewField>
              )}
              {(aifReview || (curriculumReview && values.rating)) === "1" && (
                <>
                  <strong>
                    Are there issues with this {aifReview ? "feedback" : "proposed curriculum edit"}
                    ? (Select all that apply)
                  </strong>
                  <TaskReviewField
                    name="taskIssues"
                    type="checkbox"
                    value="vague"
                    label="It is too vague"
                    onChange={() => handleCheckboxChange("taskIssues", "vague")}
                    checked={values.taskIssues?.includes("vague")}
                  />
                  <TaskReviewField
                    name="taskIssues"
                    type="checkbox"
                    value="too detailed"
                    label="It is too detailed, removing the challenge"
                    onChange={() => handleCheckboxChange("taskIssues", "too detailed")}
                    checked={values.taskIssues?.includes("too detailed")}
                  />
                  <TaskReviewField
                    name="taskIssues"
                    type="checkbox"
                    value="incorrect"
                    label="It presents incorrect information"
                    onChange={() => handleCheckboxChange("taskIssues", "incorrect")}
                    checked={values.taskIssues?.includes("incorrect")}
                  />
                  <TaskReviewField
                    name="taskIssues"
                    type="checkbox"
                    value="misleading"
                    label="It presents correct but misleading information"
                    onChange={() => handleCheckboxChange("taskIssues", "misleading")}
                    checked={values.taskIssues?.includes("misleading")}
                  />
                  <TaskReviewField
                    name="taskIssues"
                    type="checkbox"
                    value="unrelated"
                    label="It presented unrelated information"
                    onChange={() => handleCheckboxChange("taskIssues", "unrelated")}
                    checked={values.taskIssues?.includes("unrelated")}
                  />
                </>
              )}
            </Step>
            <Step beforeNext={stepValidation(["notes"])}>
              {aifReview || curriculumReview ? (
                <TaskReviewField
                  name="notes"
                  label="Anything else? (Optional)"
                  as="textarea"
                  rows={3}
                />
              ) : (
                <TaskReviewField
                  name="notes"
                  label="Please provide us with your notes on this material"
                  as="textarea"
                  rows={5}
                />
              )}
            </Step>
            <div className="step-button-container">
              <Button type="button" onClick={back} variant="secondary">
                Back
              </Button>
              {step < stepCount - 1 ? (
                <Button
                  type="button"
                  onClick={event => {
                    event.preventDefault();
                    next();
                  }}
                >
                  Next
                </Button>
              ) : (
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const Wrapper = ({ ...props }) => (
  <StepProvider>
    <TaskReviewForm {...props} />
  </StepProvider>
);

export default Wrapper;
