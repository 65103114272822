import AssistanceReviewForm from "./AssistanceReviewForm";
import ModalBackdrop from "../ModalBackdrop";
import StudentInfo from "../StudentInfo";
import "Assets/styles/scrollable-modal.scss";
import { ModalTitle } from "../Typography/Heading";

const AssistanceReviewModal = ({
  assistanceRequest,
  hide,
  nextForm,
  numberOfStudents,
  multipleStudents,
  index,
  backFn,
  answers,
  isLast,
}) => {
  const titleProgress = !multipleStudents ? "" : `(${index + 1} of ${numberOfStudents})`;

  return (
    <ModalBackdrop>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>Review Assistance {titleProgress}</ModalTitle>
            <button
              type="button"
              className="close"
              data-cy="assistance-review-modal-close"
              onClick={hide}
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <b>Feedback for student:</b>
              <StudentInfo
                student={assistanceRequest.requestor}
                showDetails={true}
                when={assistanceRequest.startAt}
                tags={assistanceRequest.tags}
                is_lead={!assistanceRequest.parentId}
              />
            </div>
            <AssistanceReviewForm
              onSubmit={nextForm}
              assistanceRequest={assistanceRequest}
              prevForm={backFn}
              isLead={index === 0}
              isLast={isLast}
              answers={answers}
            />
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default AssistanceReviewModal;
