import classNames from "classnames";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { REQUEST_TYPES, PROGRAMS } from "constants/assistanceRequest";

const RequestSelector = ({
  selectedRequestType,
  selectRequestType,
  selectedProgram,
  selectProgram,
}) => {
  return (
    <div className={`card card-default queue-selector open mb-3`}>
      <div className="card-header-back"></div>
      <div className="card-header-border"></div>
      <div className="card-header request-filters">
        <div className="options" data-cy="admin-queue-filters">
          <OverlayTrigger placement="bottom" overlay={<Tooltip>All Requests</Tooltip>}>
            <div
              className={classNames("option", "all", "bg-primary", {
                selected: !selectedRequestType,
              })}
              onClick={() => selectRequestType(null)}
            >
              <i className="fa-solid fa-comments" />
            </div>
          </OverlayTrigger>
          <div className="mentors">
            {REQUEST_TYPES.map(requestType => {
              return (
                <OverlayTrigger
                  key={requestType.id}
                  placement="bottom"
                  overlay={<Tooltip>{requestType.name}</Tooltip>}
                >
                  <div
                    key={requestType.id}
                    onClick={() => selectRequestType(requestType)}
                    className={classNames("option", {
                      duty: false,
                      selected: selectedRequestType && selectedRequestType.id === requestType.id,
                    })}
                  >
                    <i className={`fa-solid ${requestType.icon}`} />
                  </div>
                </OverlayTrigger>
              );
            })}
          </div>
        </div>
        <div className="options">
          <OverlayTrigger placement="bottom" overlay={<Tooltip>All Programs</Tooltip>}>
            <div
              className={classNames("option", "all", "bg-primary", {
                selected: !selectedRequestType,
              })}
              onClick={() => selectProgram(null)}
            >
              <i className="fa-solid fa-chalkboard-user" />
            </div>
          </OverlayTrigger>
          <div className="mentors">
            {PROGRAMS.map(program => {
              return (
                <OverlayTrigger
                  key={program.id}
                  placement="bottom"
                  overlay={<Tooltip>{program.name}</Tooltip>}
                >
                  <div
                    key={program.id}
                    onClick={() => selectProgram(program)}
                    className={classNames("option", {
                      duty: false,
                      selected: selectedProgram && selectedProgram.id === program.id,
                    })}
                  >
                    <i className={`fa-solid ${program.icon}`} />
                  </div>
                </OverlayTrigger>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestSelector;
