import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import useSettings from "Hooks/useSettings";
import "./Navbar.scss";

const MainNavigation = ({ children }) => {
  const { isLoading, settings } = useSettings();

  const logoTitle = settings?.ui?.whiteLabel?.logoTitle || "Rudder by Lighthouse Labs";

  return (
    !isLoading && (
      <Navbar className="lhl-nav" bg="light" expand="md" variant="light">
        <Link to="/" title={logoTitle}>
          {settings.ui?.whiteLabel?.logoUrl ? (
            <img
              src={settings.ui?.whiteLabel?.logoUrl}
              style={{ height: 40 }}
              alt={settings.ui?.whiteLabel?.logoTitle}
            />
          ) : (
            <h1 className="brand">rudder</h1>
          )}
        </Link>
        {children}
      </Navbar>
    )
  );
};

export default MainNavigation;
