import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { Button, Alert } from "react-bootstrap";
import * as Yup from "yup";
import { startCase } from "lodash";
import InputField from "Components/Form/InputField";
import ErrorList from "Components/Form/ErrorList";
import Requests from "Requests";
import useTags from "Hooks/useTags";

const RequestField = props => <InputField prefix="request" {...props} />;

const initialValues = {
  taskType: "",
  reason: "",
  link: "",
};

const requestSchema = Yup.object().shape({
  taskType: Yup.string().required("Please provide a task type"),
  reason: Yup.string().required("Please describe the reason for this task"),
  link: Yup.string().url().required("Please provide a task url"),
});

const TaskCreationForm = ({ user }) => {
  const { tags } = useTags();
  const [status, setStatus] = useState();
  const [tagNames, setTagNames] = useState([]);
  const navigate = useNavigate();

  const selectTag = name => setTagNames([...tagNames, name]);
  const removeTag = name => setTagNames(tagNames.filter(tagName => tagName !== name));
  const isTaskCategory = category =>
    category === "program" || category === "topic" || category === "accommodation";

  const categorizedTags = tags.reduce((acc, tag) => {
    const category = tag.settings?.category?.toLowerCase();
    if (!isTaskCategory(category)) return acc;

    acc[category] = acc[category] || [];
    acc[category].push(tag);
    return acc;
  }, {});

  const tagButtons = Object.entries(categorizedTags).map(([category, tags]) => (
    <div key={category}>
      <small>{startCase(category)}</small> <br />
      {tags.map(tag => {
        const active = tagNames.includes(tag.name);
        return (
          <Button
            key={tag.id}
            size="sm"
            className="mr-1 mb-1"
            variant={active ? "primary" : "outline-secondary"}
            onClick={active ? () => removeTag(tag.name) : () => selectTag(tag.name)}
          >
            {tag.name}
          </Button>
        );
      })}
    </div>
  ));

  const onSubmit = values => {
    const request = {
      ...values,
      requestType: "TaskRequest",
      requestedBy: user.fullName,
      intake_source: "admin",
      route: true,
    };

    Requests.createTaskRequest({ request, tagNames })
      .then(response => (response.id ? navigate("/queue/admin") : setStatus("error")))
      .catch(() => setStatus("error"));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={requestSchema} onSubmit={onSubmit}>
      {({ isSubmitting, touched, errors }) => {
        return (
          <>
            <h1>Create New Secondary Task</h1>
            <p>Hi, {user.firstName}! Let's create a new Rudder Secondary Task! </p>
            {status === "error" && (
              <Alert variant="warning">
                Sorry, your task could not be created. Please try again.
              </Alert>
            )}
            <ErrorList touched={touched} errors={errors} />
            <Form>
              <RequestField
                name="taskType"
                label="What type of secondary task is this?"
                as="select"
              >
                <option value="" disabled>
                  Please select a task type...
                </option>
                <option value="Curriculum Review">Shipwright PR Review Task</option>
                <option value="AIF Review">AIF Review Task</option>
                <option value="Larry Review">Larry Review Task</option>
              </RequestField>
              <RequestField
                name="reason"
                label="Please provide more information for the mentors"
                as="textarea"
                rows={4}
                placeholder="Provide as much information as possible to help the mentor complete this task"
              />
              <RequestField
                name="link"
                label="Provide a link to the external task here"
                placeholder="https://www.example.com"
              />
              <strong>Select tags for this task</strong>
              {tagButtons}
              <div className="step-button-container">
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default TaskCreationForm;
