import { useState, useEffect } from "react";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import useDeployments from "Hooks/useDeployments";
import ListGroup from "Components/ListGroup";
import Tabs from "Components/Tabs";
import FeatureSettingsForm from "./FeatureSettingsForm";
import UiSettingsForm from "./UiSettingsForm";
import QueueSettingsForm from "./QueueSettingsForm";

const DeploymentSettings = () => {
  const { deployments, editFeatureSettings, editUiSettings, editQueueSettings } = useDeployments();
  const [selectedDeploymentId, setSelectedDeploymentId] = useState();
  const [selectedDeployment, setSelectedDeployment] = useState();
  const [featureSettings, setFeatureSettings] = useState();
  const [queueSettings, setQueueSettings] = useState();
  const [uiSettings, setUiSettings] = useState();
  const [status, setStatus] = useState({ loading: true });
  const [showTab, setShowTab] = useState("feature");

  useEffect(() => {
    if (deployments?.length > 0) setStatus({ loading: false });
    setSelectedDeploymentId(deployments[0]?.id);
  }, [deployments]);

  useEffect(() => {
    if (!selectedDeploymentId) return;
    setSelectedDeployment(deployments.find(deployment => deployment.id === selectedDeploymentId));
  }, [selectedDeploymentId, deployments]);

  useEffect(() => {
    setFeatureSettings(selectedDeployment?.settings?.features);
    setUiSettings(selectedDeployment?.settings?.ui);
    setQueueSettings(selectedDeployment?.settings?.task_router_settings);
  }, [selectedDeployment]);

  const onChangeTab = tab => setShowTab(tab);

  const updateSettings = (settingsType, settings) => {
    const determineEditFunction = type => {
      switch (type) {
        case "feature":
          return editFeatureSettings;
        case "ui":
          return editUiSettings;
        case "queue":
          return editQueueSettings;
      }
    };

    const editSettings = determineEditFunction(settingsType);

    if (confirm(`Are you sure you want to change the deployment's ${settingsType} settings?`)) {
      setStatus({ loading: true });
      editSettings(selectedDeploymentId, settings)
        .then(response => setStatus(prev => ({ ...prev, message: response.message })))
        .catch(response => setStatus(prev => ({ ...prev, message: response.message })))
        .finally(() => {
          setStatus(prev => ({ ...prev, loading: false }));
          window.scrollTo(0, 0);
        });
    }
  };

  const handleSubmitFeatureSettings = e => {
    e.preventDefault();
    updateSettings("feature", featureSettings);
  };

  const handleSubmitUiSettings = e => {
    e.preventDefault();
    updateSettings("ui", uiSettings);
  };

  const handleSubmitQueueSettings = e => {
    e.preventDefault();
    updateSettings("queue", queueSettings);
  };

  const Tab = () => {
    switch (showTab) {
      case "feature":
        return (
          <>
            {featureSettings && (
              <FeatureSettingsForm
                onSubmit={handleSubmitFeatureSettings}
                featureSettings={featureSettings}
                setFeatureSettings={setFeatureSettings}
              />
            )}
          </>
        );
      case "UI":
        return (
          <>
            {uiSettings && (
              <UiSettingsForm
                onSubmit={handleSubmitUiSettings}
                uiSettings={uiSettings}
                setUiSettings={setUiSettings}
              />
            )}
          </>
        );
      case "queue":
        return (
          <>
            {queueSettings && (
              <QueueSettingsForm
                onSubmit={handleSubmitQueueSettings}
                queueSettings={queueSettings}
                setQueueSettings={setQueueSettings}
              />
            )}
          </>
        );
    }
  };

  const icon = () => <i className="fa-solid fa-gears" />;

  return (
    <>
      <div className="d-flex align-items-center mx-3 mb-3">
        <label className="mr-2 mb-0">Deployment:</label>
        <Form.Control as="select" onChange={e => setSelectedDeploymentId(Number(e.target.value))}>
          {deployments.map(d => (
            <option key={d.id} value={d.id}>
              {d.name}
            </option>
          ))}
        </Form.Control>
      </div>
      <ListGroup icon={icon()} title="Deployment Settings">
        <div className="national-queue-stats p-4">
          {(status.error || status.message) && (
            <div
              className={classNames("alert", {
                "alert-danger": status.error,
                "alert-success": !status.error,
              })}
            >
              <strong>{status.error || status.message}</strong>
            </div>
          )}

          <Tabs tab={showTab} tabs={["feature", "UI", "queue"]} onChangeTab={onChangeTab}>
            <Tab />
          </Tabs>
        </div>
      </ListGroup>
    </>
  );
};

export default DeploymentSettings;
