import MentorStatusList from "Components/MentorStatusList";
import Dropdown from "react-bootstrap/Dropdown";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";
import ResponsiveLabel from "Components/ResponsiveLabel";
import useLearnerQueueSchedule from "Hooks/useLearnerQueueSchedule";
import { useAppContext } from "Context/AppProvider";

const MentorStatusButton = ({ teachers, nav }) => {
  const { currentUser } = useAppContext();
  const { queueClosed } = useLearnerQueueSchedule();
  if (!currentUser || (currentUser && queueClosed)) return <></>;

  const sortedTeachers = [...teachers].sort((a, b) => a.fullName.localeCompare(b.fullName));
  const teacherStatus = teachers.length === 1 ? " Teacher on duty" : " Teachers on duty";

  const title = (
    <>
      <i className="fa fa-fw fa-list" />
      <strong>{` ${teachers.length}`}</strong>
      <ResponsiveLabel sizes={nav ? ["small", "xsmall"] : ["small", "medium", "large"]}>
        {teacherStatus}
      </ResponsiveLabel>
    </>
  );

  return (
    <Dropdown as={NavItem} className="toggle-onduty-mentors" data-cy="mentor-list">
      <Dropdown.Toggle as={NavLink}>{title}</Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
        <MentorStatusList nav={nav} teachers={sortedTeachers} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MentorStatusButton;
