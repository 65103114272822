import { useState, useCallback } from "react";
import { useAppContext } from "Context/AppProvider";
import requests from "Requests";
import classNames from "classnames";
import Avatar from "Components/Avatar";
import Button from "react-bootstrap/Button";
import { DateTime } from "luxon";
import SuggestedAssistanceStatusBadge from "./SuggestedAssistanceStatusBadge";
import "./SuggestedAssistanceRequest.scss";

const SuggestedAssistanceRequest = ({ assistanceRequest }) => {
  const {
    state,
    canceledAt,
    cancellationReason,
    requestor,
    request,
    startAt,
    requestCategory,
    conference,
  } = assistanceRequest;
  const { currentUser } = useAppContext();
  const [fullReasonDisplay, setFullReasonDisplay] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const formattedStartDate = DateTime.fromISO(startAt).toRelative();

  const toggleFullReasonDisplay = useCallback(() => {
    setFullReasonDisplay(prev => !prev);
  }, []);

  const logRating = rating => {
    return requests
      .createSuggestedAssistanceFeedback({
        feedback: {
          assistance_request_id: assistanceRequest.id,
          learner_id: currentUser.id,
          helpful: rating === "thumbs-up",
        },
      })
      .then(setFeedback(rating))
      .catch(err => console.log(err));
  };

  return (
    <div className="suggested-ars-list-item list-group-item">
      <div className="requestors">
        <Avatar
          to={`/learners/${requestor.uid}`}
          src={requestor.avatarUrl}
          busy={requestor.busy}
          onDuty={requestor.onDuty}
          name={requestor.fullName}
        />
        <small>{requestor.fullName}</small>
      </div>
      <div className="request">
        <div className="request-badges">
          <div className="badge badge-info">{requestCategory?.displayText}</div>
          <SuggestedAssistanceStatusBadge
            state={state}
            canceledAt={canceledAt}
            cancellationReason={cancellationReason}
          />
        </div>

        <div
          className={classNames("note", {
            preview: !fullReasonDisplay,
          })}
          onClick={toggleFullReasonDisplay}
        >
          &quot;{request.reason}&quot;
        </div>
        <div className="start-time">{formattedStartDate}</div>

        <a className="button-link" href={conference?.link} target="_blank" rel="noreferrer">
          <Button className="join-button" variant="outline-primary" size="sm">
            See Discord Post
          </Button>
        </a>
        {feedback ? (
          <div className="helpful-rating">
            <i className={`fa-solid fa-${feedback}`} />
            Thanks for your feedback!
          </div>
        ) : (
          <div className="helpful-rating">
            <i
              className="fa-regular fa-thumbs-up"
              data-cy="suggested-assistance-thumbs-up-rating"
              onClick={() => logRating("thumbs-up")}
            />
            <i
              className="fa-regular fa-thumbs-down"
              data-cy="suggested-assistance-thumbs-down-rating"
              onClick={() => logRating("thumbs-down")}
            />
            Was this suggestion helpful?
          </div>
        )}
      </div>
    </div>
  );
};

export default SuggestedAssistanceRequest;
