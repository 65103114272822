// Component for rendering code blocks with syntax highlighting for use with ReactMarkdown
// Using a light build. If additional languages are required, add them to the imports and registers here
// Written using the following gist as reference and modified to work for our needs: https://gist.github.com/ilyazub/e58245ad6e76ddd9f0f221b7e4870398
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/prism/javascript";
import json from "react-syntax-highlighter/dist/esm/languages/prism/json";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import ruby from "react-syntax-highlighter/dist/esm/languages/prism/ruby";
import python from "react-syntax-highlighter/dist/esm/languages/prism/python";
import sql from "react-syntax-highlighter/dist/esm/languages/prism/sql";
import dark from "react-syntax-highlighter/dist/esm/styles/prism/dracula";

SyntaxHighlighter.registerLanguage("javascript", js);
SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("jsx", jsx);
SyntaxHighlighter.registerLanguage("ruby", ruby);
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("sql", sql);

const Code = ({ inline, className, children }) => {
  // use standard code tag if inline
  if (inline) {
    return <code>{children}</code>;
  }

  // ReactMarkdown passes language to code blocks in className as "language-x" and Prism only wants the "x" part
  const language = className ? className.split("-")[1] : null;

  return (
    <SyntaxHighlighter language={language} style={dark} showLineNumbers>
      {children}
    </SyntaxHighlighter>
  );
};

export default Code;
