import TimeAgoWithTooltip from "Components/TimeAgoWithTooltip";
import Pronoun from "Components/Pronoun";
import Avatar from "Components/Avatar";
import { useAppContext } from "Context/AppProvider";
import { generateProfileUrl } from "util/user";

const AssistanceStartTime = ({ when }) => {
  if (when) return <TimeAgoWithTooltip when={when} />;

  return null;
};

const TeacherInfo = ({ assistor, when }) => {
  const {
    currentUser,
    teacherHandler: { isTeacherOnDuty, isTeacherBusy },
  } = useAppContext();

  return (
    <div className="assister clearfix">
      <div className="arrow">
        <span>&#10551;</span>
      </div>
      <Avatar
        to={generateProfileUrl({ currentUser, userId: assistor.id })}
        src={assistor.avatarUrl}
        busy={isTeacherBusy(assistor.uid)}
        onDuty={isTeacherOnDuty(assistor.uid)}
        name={assistor.fullName}
      />
      <div className="name">
        {assistor.fullName}
        <br />
        <Pronoun pronoun={assistor.pronoun} />
      </div>
      <div className="details">
        <AssistanceStartTime when={when} />
      </div>
    </div>
  );
};

export default TeacherInfo;
