import classNames from "classnames";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useAppContext } from "Context/AppProvider";

const MentorDutyButton = ({ user, admin, disabled, outline }) => {
  const {
    queueChannel: { toggleDuty },
  } = useAppContext();

  const dutyText = () => {
    // $(dutyButtonRef.current).tooltip();
    if (disabled) {
      return "";
    } else if (user) {
      return `Go ${user.onDuty ? "off" : "on"} duty${admin ? ` for ${user.firstName}` : ""}`;
    }
  };

  const borderType = outline ? "-outline" : "";
  const dutyClass = classNames("btn-toggle-duty", "mr-1", "btn", {
    [`btn${borderType}-secondary`]: !user,
    [`btn${borderType}-danger`]: user && user.onDuty,
    [`btn${borderType}-success`]: user && !user.onDuty,
  });

  const handleToggleDuty = () => toggleDuty(user);

  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip>{dutyText()}</Tooltip>}>
      <button className={dutyClass} onClick={handleToggleDuty} disabled={disabled}>
        <i className="fa fa-fw fa-bullhorn" />
      </button>
    </OverlayTrigger>
  );
};

export default MentorDutyButton;
