import { Link } from "react-router-dom";
import { useAppContext } from "Context/AppProvider";
import ToggleSwitch from "Components/bootstrap/ToggleSwitch";
import SettingsConditional from "Components/SettingsConditional";

const QueueMenu = () => {
  const {
    queueChannel: { connected },
    currentUser,
  } = useAppContext();

  return (
    <div className="queue-header">
      <div className="navigation">
        <h2 className="queue-title">the queue</h2>
        {!connected && <i className="fa-solid fa-spinner text-primary queue-loader" />}
      </div>

      <div className="actions">
        {currentUser.isAdmin && (
          <Link to="/queue/admin" className="admin-toggle">
            <ToggleSwitch label="Toggle Admin Queue" />
          </Link>
        )}
        <span className="spacer"></span>
        <SettingsConditional flag={SettingsConditional.ON_DUTY} user={currentUser} />
      </div>
    </div>
  );
};

export default QueueMenu;
