import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import Code from "./Code";

// modified version of default ReactMarkdown component
// adds plugin for rendering newlines consistently and component for code block syntax highlighting
const Markdown = ({ children }) => (
  <div className="markdown-content">
    <ReactMarkdown remarkPlugins={[remarkBreaks]} components={{ code: Code }}>
      {children}
    </ReactMarkdown>
  </div>
);

export default Markdown;
