import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useAppContext } from "Context/AppProvider";
import Button from "react-bootstrap/Button";
import SettingsConditional from "Components/SettingsConditional";
import ResponsiveLabel from "Components/ResponsiveLabel";
import { Link } from "react-router-dom";

const MentorNavigation = () => {
  const { currentUser } = useAppContext();

  return (
    <>
      <SettingsConditional flag={SettingsConditional.ON_DUTY} outline="true" user={currentUser} />
      <Button as={Link} to="/queue" variant="outline-primary" className="mr-1">
        <i className="fa fa-fw fa-list-ol" />
        <ResponsiveLabel sizes={["small", "xsmall"]}>Queue</ResponsiveLabel>
      </Button>

      <SettingsConditional flag={SettingsConditional.HIDE_LOG_ISSUE}>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Log Issue</Tooltip>}>
          <a
            href="https://github.com/lighthouse-labs/rudder-rails/issues"
            className="btn btn-outline-warning"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-fw fa-exclamation" />
          </a>
        </OverlayTrigger>
      </SettingsConditional>
    </>
  );
};

export default MentorNavigation;
