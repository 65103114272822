import classNames from "classnames";
import useRequestState from "Hooks/useRequestState";
import Spinner from "../Spinner";
import { assistanceButtonIcons } from "util/styles";
import { useAssistanceModalContext } from "Context/AssistanceModalProvider";
import { DesktopOverlayTrigger } from "../bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";

const useModalState = RequestStatuses => {
  const {
    setShowCreateRequestModal,
    setShowViewRequestModal,
    setShowNoMentorModal,
    setShowAIOnlyModal,
  } = useAssistanceModalContext();

  const showModalsBasedOnState = ({
    currentRequestState,
    profileIncomplete,
    allowAsync,
    teachers,
    allowAIOnly,
    inPrep,
    queueClosed,
  }) => {
    if (currentRequestState.status !== RequestStatuses.NONE) {
      setShowViewRequestModal(true);
    } else if (profileIncomplete) {
      editProfile();
    } else if (!allowAsync && !teachers.length && !allowAIOnly) {
      setShowNoMentorModal(true);
    } else if ((queueClosed || inPrep) && allowAIOnly) {
      setShowAIOnlyModal(true);
    } else {
      setShowCreateRequestModal(true);
    }
  };

  return showModalsBasedOnState;
};

const AssistanceButtonInfo = ({
  forDashboard,
  buttonText,
  profileIncomplete,
  aiOnly,
  missingExternalChannel,
}) => {
  if (!forDashboard) return buttonText;

  const getIconKey = () => {
    if (profileIncomplete) return "profileIncomplete";
    if (aiOnly) return "usAIOnly";
    if (missingExternalChannel) return "missingExternalChannel";
    return "default";
  };

  const key = getIconKey();

  return (
    <>
      <i className={`fa fa-fw ${assistanceButtonIcons[key]}`} />
      <span>{buttonText}</span>
    </>
  );
};

const MainAssistanceButton = props => {
  const { RequestStatuses, getButtonInfo } = useRequestState();
  const showModalsBasedOnState = useModalState(RequestStatuses);
  const button = getButtonInfo(props);

  const {
    queueChannel,
    queueClosed,
    currentRequestState,
    requestBeforeQueueEnabled,
    allowAIOnly,
    inPrep,
    forDashboard,
    profileIncomplete,
    chatAssistance,
    externalChannel,
  } = props;

  const handleAssistanceButton = e => {
    e.preventDefault();
    e.stopPropagation();
    showModalsBasedOnState(props);
  };

  const missingExternalChannel = chatAssistance && externalChannel === "missing";
  const buttonDisabled =
    !queueChannel.connected ||
    (queueClosed &&
      currentRequestState.status === RequestStatuses.NONE &&
      !requestBeforeQueueEnabled &&
      !allowAIOnly) ||
    missingExternalChannel;

  const buttonClass = classNames("navbar-btn", "btn", button.style, {
    "dashboard-button": forDashboard,
    "ai-only-button": forDashboard && queueClosed && allowAIOnly,
  });

  return (
    <DesktopOverlayTrigger placement="bottom" overlay={<Tooltip>{button.title}</Tooltip>}>
      <button className={buttonClass} onClick={handleAssistanceButton} disabled={buttonDisabled}>
        <div>
          {queueChannel.connected ? (
            <AssistanceButtonInfo
              forDashboard={forDashboard}
              buttonText={button.text}
              profileIncomplete={profileIncomplete}
              aiOnly={(queueClosed || inPrep) && allowAIOnly}
              missingExternalChannel={missingExternalChannel}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </button>
    </DesktopOverlayTrigger>
  );
};

export default MainAssistanceButton;
