import { Link } from "react-router-dom";
import SocialIcons from "Components/SocialIcons";
import TimeAgoWithTooltip from "Components/TimeAgoWithTooltip";
import Pronoun from "Components/Pronoun";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Avatar from "Components/Avatar";
import Badge from "react-bootstrap/Badge";
import "./StudentInfo.scss";
import { getAvatarUrl } from "util/avatar";
import { generateProfileUrl } from "util/user";
import { useAppContext } from "Context/AppProvider";

const getUniqueTags = (tags = [], student = {}) => {
  const allTags = [...(student.tags || []), ...tags];
  const uniqueTagsMap = new Map(allTags.map(tag => [tag.name, tag]));

  return [...uniqueTagsMap.values()];
};

const LastAssistedAt = ({ when, show }) => {
  if (!show) return null;
  return when ? <TimeAgoWithTooltip when={when} /> : <small>Never Helped</small>;
};

const StudentDetails = ({
  showDetails,
  hideTags,
  student,
  details,
  allTags,
  when,
  showLastAssistedAt,
  excludeStudentTags,
}) => {
  if (!showDetails) return null;

  const detailsToInclude = ["week", "location", "program_name"];
  const badges = Object.entries({ ...details, ...student.info })
    .filter(([name]) => detailsToInclude.includes(name))
    .map(([name, info]) => (
      <Badge key={name} bg="light">
        {info}
      </Badge>
    ));

  if (!hideTags) {
    const tags = getUniqueTags(allTags, excludeStudentTags ? {} : student);

    for (const tag of tags) {
      const background = tag.settings?.badge?.background || "warning";
      const text = tag.settings?.badge?.text || "dark";

      badges.push(
        <OverlayTrigger
          key={`tagbadge-${tag.name}`}
          placement="bottom"
          overlay={<Tooltip>{tag.queueNote || tag.name}</Tooltip>}
        >
          <Badge className="ml-1 rudder-tag" bg={background} text={text}>
            {tag.name}
          </Badge>
        </OverlayTrigger>
      );
    }
  }

  return (
    <div className="student-details-container">
      <div className="details">{badges}</div>
      <LastAssistedAt when={when} show={showLastAssistedAt} />
    </div>
  );
};

const Title = ({ student, isLead, nonLearner, profileLink }) => {
  const { fullName, pronoun } = student;
  const shouldShowTitleOnly = nonLearner || !profileLink;
  const title = (
    <>
      {fullName} <Pronoun pronoun={pronoun} /> {isLead ? "(lead)" : ""}
    </>
  );

  return (
    <div className="title">
      {shouldShowTitleOnly ? title : <Link to={profileLink}>{title}</Link>}
    </div>
  );
};

const StudentInfo = ({
  student,
  when,
  showDetails,
  hideTags,
  details,
  tags,
  is_lead,
  simple,
  nonLearner,
  excludeStudentTags,
}) => {
  const { currentUser } = useAppContext();
  const profileLink = generateProfileUrl({ currentUser, studentUid: student.uid });
  const avatarUrl =
    student.avatarUrl || getAvatarUrl(student.email || (student.socials && student.socials.email));

  return (
    <div className="student-info-container">
      <Avatar
        to={profileLink}
        src={avatarUrl}
        busy={student.busy}
        onDuty={student.onDuty}
        name={student.fullName}
      />
      <div className="student-info">
        <div className="name">
          <Title
            student={student}
            isLead={is_lead}
            nonLearner={nonLearner}
            profileLink={profileLink}
          />
          <br />
          <SocialIcons user={student} />
        </div>
        <StudentDetails
          showDetails={showDetails}
          hideTags={hideTags}
          details={details}
          student={student}
          allTags={tags}
          simple={simple}
          when={when}
          showLastAssistedAt={!nonLearner}
          excludeStudentTags={excludeStudentTags}
        />
      </div>
    </div>
  );
};

export default StudentInfo;
