import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import useTags from "Hooks/useTags";
import TagForm from "./TagForm";
import { Card } from "react-bootstrap";
import PaddedContent from "Layouts/PaddedContent";

const New = () => {
  const { addTag } = useTags();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const handleCreateTag = system => {
    setError(null);
    return addTag(system, {
      onSuccess: data => {
        navigate(`/tags/${data.id}/edit`);
      },
      onError: () => {
        setError(true);
      },
    });
  };

  return (
    <PaddedContent>
      <Link to="/tags">
        <i className="fa fa-fw fa-arrow-left" />
      </Link>
      <h1>New Tag</h1>
      <Card body>
        {error && <Alert variant="danger">Tag could not be Added!</Alert>}
        <TagForm onSubmit={handleCreateTag} />
      </Card>
    </PaddedContent>
  );
};

export default New;
