import { useNavigate, Link, useLocation } from "react-router-dom";
import TagsList from "./TagsList";
import useTags from "Hooks/useTags";
import { useRef, useEffect } from "react";
import { InputGroup, Form, Button } from "react-bootstrap";
import { useAppContext } from "Context/AppProvider";
import { toInteger } from "lodash";
import Pagination from "Components/Pagination";
import "./Tags.scss";
import queryString from "query-string";
import Spinner from "Components/Spinner";
import PaddedContent from "Layouts/PaddedContent";

const Browse = () => {
  const { currentUser } = useAppContext();
  const navigate = useNavigate();
  const { search } = useLocation();
  const inputRef = useRef(null);
  const { parse, stringify } = queryString;
  const { page, query, category } = queryString.parse(search);
  const searchQuery = query || "";
  const pageNumber = /^[0-9]+$/.test(page) ? toInteger(page) : 1;
  const selectedCategory = category || "";
  const { tags, lastPage, deleteTag, isLoading, error, refetch } = useTags(
    pageNumber,
    searchQuery,
    selectedCategory
  );

  useEffect(() => {
    refetch();
  }, [pageNumber, searchQuery, selectedCategory, refetch]);

  const edit = system => navigate(`/tags/${system.id}/edit`);

  const updateQuery = updates => {
    const updatedQuery = stringify({ page: 1, ...parse(search), ...updates });
    navigate(`?${updatedQuery}`);
  };

  const handleSearch = query => updateQuery({ query, category: category });
  const handleCategoryFilter = selectedCategory =>
    updateQuery({ query: searchQuery, category: selectedCategory });

  const remove = tag => {
    if (window.confirm("Are you sure you would like to delete this tag?")) {
      deleteTag(tag);
    }
  };

  return (
    <PaddedContent>
      <span className="loading">{isLoading && <Spinner />}</span>
      {lastPage && (
        <Pagination
          routeKey={"/tags"}
          pageNumber={pageNumber}
          lastPage={lastPage}
          ariaLabel="Tags pagination"
        />
      )}
      <InputGroup className="mb-3">
        <Form.Control
          ref={inputRef}
          placeholder="Search by tag name"
          aria-label="Search"
          aria-describedby="basic-addon2"
          onKeyPress={e => {
            if (e.key === "Enter") handleSearch(e.target.value.trim());
          }}
        />
        <Button
          variant="outline-primary"
          onClick={() => handleSearch(inputRef.current.value.trim())}
        >
          Search
        </Button>
        <Button
          variant="outline-secondary"
          id="button-addon2"
          onClick={e => {
            e.preventDefault();
            inputRef.current.value = "";
            handleSearch(inputRef.current.value.trim());
          }}
        >
          Clear
        </Button>
        <Form.Select onChange={e => handleCategoryFilter(e.target.value)}>
          <option value="">All Categories</option>
          <option value="program">Program</option>
          <option value="accommodation">Accommodation</option>
          <option value="request-type">Request Type</option>
          <option value="topic">Topic</option>
          <option value="demo">Demo</option>
        </Form.Select>
      </InputGroup>
      <div className="title-holder">
        <h1>Tags</h1>
        {currentUser.isSuperAdmin && (
          <Link to="/tags/new">
            <i className="fa fa-fw fa-plus" />
          </Link>
        )}
      </div>
      {!isLoading && error && <p>Something went wrong. {error}</p>}
      {!isLoading && tags && (
        <TagsList tags={tags} edit={edit} remove={remove} currentUser={currentUser} />
      )}
    </PaddedContent>
  );
};

export default Browse;
