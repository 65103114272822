import { useAppContext } from "Context/AppProvider";
import Button from "react-bootstrap/Button";
import useExternalUser from "Hooks/useExternalUser";
import Spinner from "./Spinner";

const ExternalUserAuth = ({ platform, children }) => {
  const { currentUser } = useAppContext();
  const { externalUser, isLoading, error } = useExternalUser(currentUser, platform);

  // loading screen while fetching external user record
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center m-2">
        <Spinner />
        &nbsp;Checking user authorization...
      </div>
    );
  }

  // render authorization notice if no external user is found
  if (!externalUser.id) {
    return (
      <div className="modal-body">
        {error && (
          <div className="alert alert-danger">
            <strong>{error}</strong>
          </div>
        )}
        <p>
          You need to link your Discord account to be able to request assistance. For that, please
          click the authorization button below.
          <br />
        </p>
        <a
          className="px-7"
          href={`${process.env.REACT_APP_SERVER}/oauth/authorize?platform=${platform}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button>Authorize your account with Discord</Button>
        </a>
      </div>
    );
  }

  // if authorization is required and found, render children as usual
  return <>{children}</>;
};

export default ExternalUserAuth;
