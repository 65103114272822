import { Fragment, useState } from "react";
import { isMobile } from "react-device-detect";
import classnames from "classnames";
import { uniqueValuesOnly } from "../../../../util/array";
import { DateTime } from "luxon";
import { humanize } from "util/datetime";
import "./Assistances.scss";

const flagValue = flag => {
  switch (flag.type) {
    case "boolean":
      return flag.value ? "Yes" : "No";
    case "scalar":
      return flag.valueDescription || `${flag.value}/${flag.rangeMax}`;
    case "scalar_smiley":
      return `${flag.value}/5`;
  }
};

const requestCancelledAt = assistance =>
  assistance.assistanceRequest?.canceledAt &&
  DateTime.fromISO(assistance.assistanceRequest.canceledAt);

const TruncatedAssistance = ({ assistance }) => {
  const cancelled = requestCancelledAt(assistance);
  return (
    <>
      <div>
        <strong>{assistance.assistor?.firstName}</strong> assisted on{" "}
        <strong>
          {DateTime.fromISO(cancelled || assistance.endAt).toFormat(isMobile ? "MMM d" : "DD")}
        </strong>
        {cancelled && " (Request cancelled)"}
      </div>
      <em className="notes truncated">{assistance.notes}</em>
    </>
  );
};

const TeacherPic = ({ assistance }) => (
  <div className="teacher-pic">
    <img src={assistance.assistor?.avatarUrl} alt="teacher-pic" />
  </div>
);

const Header = ({ assistance }) => {
  const cancelled = requestCancelledAt(assistance);
  return (
    <div className="header">
      <strong>{assistance.assistor?.fullName}</strong>

      <span>
        {cancelled && "Cancelled on "}
        {DateTime.fromISO(cancelled || assistance.endAt).toFormat("DD")}
      </span>
    </div>
  );
};

const DetailItem = ({ children }) => {
  return isMobile ? <>{children}</> : <div>{children}</div>;
};

const Details = ({ assistance }) => (
  <div className="assistance-details">
    {assistance.rating && (
      <DetailItem>
        <strong>Rating</strong>
        <span>{assistance.rating}/4</span>
      </DetailItem>
    )}
    {assistance.requestAccuracy && (
      <DetailItem>
        <strong>Request Accuracy</strong>
        <span>{assistance.requestAccuracy}/3</span>
      </DetailItem>
    )}
    {assistance.studentDifficulty && (
      <DetailItem>
        <strong>Difficulty to Solve</strong>
        <span>{assistance.studentDifficulty}/5</span>
      </DetailItem>
    )}
    {assistance.studentAttitude && (
      <DetailItem>
        <strong>Student Attitude</strong>
        <span>{assistance.studentAttitude}/5</span>
      </DetailItem>
    )}
  </div>
);

const Issue = ({ assistance }) =>
  (assistance.requestCause || assistance.requestSolution) && (
    <div className="issue">
      {assistance.requestCause && (
        <>
          <strong>Request Cause</strong>
          <em>{assistance.requestCause}</em>
        </>
      )}
      {assistance.requestSolution && (
        <>
          <strong>Request Solution</strong>
          <em>{assistance.requestSolution}</em>
        </>
      )}
    </div>
  );

const Flags = ({ assistance }) =>
  !!assistance.flags?.length && (
    <div className="assistance-flags">
      <strong>Additional Flags</strong>
      <div className="flags">
        {assistance.flags.map(flag => (
          <Fragment key={flag.name}>
            <span key={`${flag.name}-text`}>{flag.displayText}</span>
            <span key={`${flag.name}-value`}>{flagValue(flag)}</span>
          </Fragment>
        ))}
      </div>
    </div>
  );

const Notes = ({ assistance }) =>
  assistance.notes ? (
    <div className="notes">
      <strong>Notes</strong>
      <em>{assistance.notes}</em>
    </div>
  ) : null;

const AssistanceRequest = ({ assistanceRequest, endAt }) => {
  const { request, createdAt, startAt, canceledAt } = assistanceRequest;
  const created = DateTime.fromISO(createdAt);
  const started = DateTime.fromISO(startAt);
  const ended = DateTime.fromISO(endAt);
  const cancelled = DateTime.fromISO(canceledAt);
  return (
    <div className="assistance-request">
      <span className="section-header">Request Details</span>
      <div className="assistance-request-details">
        <strong>Requested</strong>
        <span>{created.toFormat("MMM D, t")}</span>
        <strong>Started</strong>
        <span>{started.toFormat("MMM D, t")}</span>
        {!cancelled && (
          <>
            <strong>Finished</strong>
            <span>{ended.toFormat("MMM D, t")}</span>
          </>
        )}
        <strong>Wait Time</strong>
        <span>{humanize(started.diff(created).toMillis())}</span>
        {!cancelled && (
          <>
            <strong>Duration</strong>
            <span>{humanize(ended.diff(started).toMillis())}</span>
          </>
        )}
        {request.activity && (
          <>
            <strong>Activity</strong>
            <span>{request.activity}</span>
          </>
        )}
        <strong>Category</strong>
        <span>{request.categoryName}</span>
        <strong>Reason</strong>
        <span>{request.reason}</span>
      </div>
    </div>
  );
};

const CompleteAssistance = ({ assistance }) => {
  const cancelled = requestCancelledAt(assistance);
  return (
    <>
      <div className="top">
        <TeacherPic assistance={assistance} />
        <div>
          <Header assistance={assistance} />
          <AssistanceRequest
            assistanceRequest={assistance.assistanceRequest}
            endAt={assistance.endAt}
          />
        </div>
      </div>
      {!cancelled && (
        <div className="details-container">
          <span className="section-header">Assistance Details</span>
          {assistance.rating ? (
            <>
              <Details assistance={assistance} />
              <Issue assistance={assistance} />
              <Flags assistance={assistance} />
              <Notes assistance={assistance} />
            </>
          ) : (
            <div>
              <strong>Not enough information on this student to review</strong>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const Assistance = ({ expanded, onClick, assistance }) => (
  <div className={classnames("assistance", { expanded })} onClick={onClick}>
    {expanded ? (
      <CompleteAssistance assistance={assistance} />
    ) : (
      <TruncatedAssistance assistance={assistance} />
    )}
  </div>
);

const Assistances = ({ assistances }) => {
  const [expandedIds, setExpandedIds] = useState([]);

  const onAssistanceClick = id => setExpandedIds([...expandedIds, id].reduce(uniqueValuesOnly, []));

  return (
    <div className="assistances">
      {assistances?.map(assistance => (
        <Assistance
          key={assistance.id}
          expanded={expandedIds.includes(assistance.id)}
          onClick={() => onAssistanceClick(assistance.id)}
          assistance={assistance}
        />
      ))}
      {assistances?.length === 0 && <p className="message">Learner has no assistances.</p>}
    </div>
  );
};

export default Assistances;
