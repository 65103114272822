import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { isMobile } from "react-device-detect";

export const ConditionalOverlayTrigger = ({ hidden, children, ...props }) => {
  return hidden ? children : <OverlayTrigger {...props}>{children}</OverlayTrigger>;
};

export const DesktopOverlayTrigger = ({ children, ...props }) => {
  return (
    <ConditionalOverlayTrigger {...props} hidden={isMobile}>
      {children}
    </ConditionalOverlayTrigger>
  );
};
