import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SettingsConditional from "Components/SettingsConditional";
import "./MainFooter.scss";

const MainFooter = () => {
  return (
    <SettingsConditional flag={SettingsConditional.HIDE_FOOTER}>
      {settings => (
        <div className="main-footer">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{settings.ui.footer.supportLinkTooltip}</Tooltip>}
          >
            <a className="tech-support" href={`mailto:${settings.mailingList?.techSupport}`}>
              {settings.ui.footer.supportLinkText || "Need help?"}
            </a>
          </OverlayTrigger>
        </div>
      )}
    </SettingsConditional>
  );
};

export default MainFooter;
