import QueueItem from "Components/QueueItem";
import StudentInfo from "Components/StudentInfo";
import RequestDetails from "Components/RequestDetails";
import { useState } from "react";
import { useAppContext } from "Context/AppProvider";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "constants/toast";
import SkipRequestModal from "Components/SkipRequestModal";
import Requests from "Requests";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const PendingEvaluation = ({ assistanceRequest }) => {
  const {
    queueChannel: { claimAssistance },
    tasksHandler,
    settingsHandler: { settings },
  } = useAppContext();

  const [disabled, setDisabled] = useState(false);
  const [showSkipRequestModal, setShowSkipRequestModal] = useState(false);
  const mentorSkip = settings?.featureFlags?.mentorSkip;
  const handleSkipAR = () => setShowSkipRequestModal(true);
  const handleClaimAR = () => {
    setDisabled(true);
    claimAssistance(assistanceRequest)
      .then(() => tasksHandler.claimAssistance(assistanceRequest.id))
      .catch(err => {
        setDisabled(false);
        toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
      });
  };

  const skipRequest = (reason, notes) => {
    setDisabled(true);

    const queueTask = tasksHandler
      .allOpenSecondaryTasksForUser()
      .find(task => task.assistanceRequest.id === assistanceRequest.id);

    Requests.skipRequest(queueTask.id, reason, notes)
      .then(() => tasksHandler.skipRequest(queueTask.id))
      .catch(err => {
        setDisabled(false);
        toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
      })
      .finally(setDisabled(false));
  };

  const isSinglePendingRequest =
    tasksHandler
      .pendingAssistanceRequests()
      .filter(ar =>
        tasksHandler
          .allOpenSecondaryTasks()
          .some(task => task.assistanceRequest.id === ar.id && !task.skippedAt)
      ).length === 1;

  const hoverText = (
    <Tooltip id="tooltip">
      <strong>Cannot skip</strong> when you have only one assigned task remaining.
    </Tooltip>
  );

  const buttonClassName = `btn btn-sm ${
    isSinglePendingRequest ? "btn-secondary" : "btn-light btn-main"
  }`;

  const skipButton = (
    <button
      key="skip"
      className={buttonClassName}
      onClick={isSinglePendingRequest || disabled ? null : handleSkipAR}
      disabled={isSinglePendingRequest}
      style={{ pointerEvents: isSinglePendingRequest ? "none" : "auto" }}
    >
      Skip
    </button>
  );

  const renderSkipButton = () =>
    isSinglePendingRequest ? (
      <OverlayTrigger placement="left" overlay={hoverText}>
        <span>{skipButton}</span>
      </OverlayTrigger>
    ) : (
      skipButton
    );

  return (
    <>
      <QueueItem label="evaluation" type="Evaluation">
        <StudentInfo
          student={assistanceRequest.requestor}
          showDetails={true}
          when={assistanceRequest.startAt}
          tags={assistanceRequest.tags}
          excludeStudentTags
        />
        <div className="blurb">
          <RequestDetails
            categoryName={assistanceRequest.request.categoryName}
            day={assistanceRequest.day}
            activityUrl={assistanceRequest.request.resourceLink}
            activityTitle={assistanceRequest.request.resourceName}
            reason={assistanceRequest.request.reason}
            resubmission={assistanceRequest.request.resubmission}
            priority={assistanceRequest.priority}
          />
        </div>
        <div className="actions float-right">
          {mentorSkip && renderSkipButton()}
          <button
            key="claim"
            className="btn btn-sm btn-warning btn-main"
            onClick={handleClaimAR}
            disabled={disabled}
          >
            Start
          </button>
        </div>
      </QueueItem>
      {showSkipRequestModal && (
        <SkipRequestModal
          requestType="task"
          skip={skipRequest}
          hide={() => setShowSkipRequestModal(false)}
        ></SkipRequestModal>
      )}
    </>
  );
};

export default PendingEvaluation;
