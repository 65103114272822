import { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import useTag from "Hooks/useTag";
import NotFound from "Components/NotFound";
import Splash from "Components/Splash";
import PaddedContent from "Layouts/PaddedContent";
import { Card, Alert } from "react-bootstrap";
import TagForm from "./TagForm";

const Edit = () => {
  const { id } = useParams();
  const { tag, editTag, deleteTag } = useTag(id);
  const navigate = useNavigate();

  const [alertState, setAlertState] = useState({
    messages: [],
    variant: "primary",
  });

  const resetAlertState = () => {
    setAlertState({
      messages: [],
      variant: "primary",
    });
  };

  if (Object.keys(tag).length < 1) {
    return <Splash />;
  } else if (tag.error) {
    if (tag.error.response && tag.error.response.status === 404) {
      return <NotFound />;
    }
    return <p>{tag.error.toString()}</p>;
  }

  const handleEditTag = tag => {
    return editTag({ ...tag, id: id })
      .then(() => {
        setAlertState({
          messages: ["Tag updated successfully!"],
          variant: "success",
        });
      })
      .catch(() => {
        setAlertState({
          messages: ["Tag failed to update."],
          variant: "danger",
        });
      });
  };

  const handleDeleteTag = () => {
    if (confirm("Are you sure you want to delete this tag?")) {
      return deleteTag(tag).then(() => {
        navigate("/tags");
      });
    }
  };

  return (
    <PaddedContent>
      <Link to="/tags">
        <i className="fa fa-fw fa-arrow-left" />
      </Link>
      <h2>{`Editing Tag: ${tag.name}`}</h2>
      <Card body className="mb-3">
        {alertState.messages.length > 0 && (
          <Alert variant={alertState.variant}>
            {alertState.messages.map((msg, index) => (
              <li key={index}>{msg}</li>
            ))}
          </Alert>
        )}
        <TagForm tag={tag} onSubmit={handleEditTag} onValidate={resetAlertState} />
      </Card>
      <h4>More actions for this Tag:</h4>
      <Button as={Link} to={`/tags/${tag.id}/edit/attributions`} variant="warning" className="mr-3">
        Edit Attributions &nbsp;
        <i className="fa-solid fa-user-tag" />
      </Button>
      <Button
        onClick={handleDeleteTag}
        variant="danger"
        className="mr-3"
        disabled={tag?.userAttributions?.length > 0}
      >
        Delete Tag &nbsp;
        <i className="fa-solid fa-trash" />
      </Button>
    </PaddedContent>
  );
};

export default Edit;
