import TaskReviewForm from "./TaskReviewForm";
import ModalBackdrop from "./ModalBackdrop";
import "Assets/styles/scrollable-modal.scss";
import { ModalTitle } from "./Typography/Heading";
import TaskMasterInfo from "./TaskMasterInfo";

const determineReviewMessage = taskType => {
  switch (taskType) {
    case "Larry Review":
      return "You are providing a review for a Larry AI Bot review task. Please include your review of Larry's conversation with the student in the modal below.";
    case "Curriculum Review":
      return "You are providing a review for a Shipwright Larry PR in the curriculum repo. You should leave your notes as comments in the PR itself. Just provide a quick overall rating and any supplementary notes here.";
    case "AIF Review":
      return "You are providing a review for a AIF Feedback review task. Please include your review of the AIF feedback that was provided in Compass in the modal below.";
    default:
      return "You are providing a review of a mentor task. Please provide a quick overall rating of the task and any supplemental notes.";
  }
};

const TaskReviewModal = ({ assistanceRequest, hide, nextForm, backFn, answers }) => {
  const taskType = assistanceRequest.request["taskType"];

  const reviewMessage = determineReviewMessage(taskType);
  return (
    <ModalBackdrop>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>Review Task</ModalTitle>
            <button
              type="button"
              className="close"
              data-cy="assistance-review-modal-close"
              onClick={hide}
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <TaskMasterInfo
              tags={assistanceRequest.tags}
              when={assistanceRequest.createdAt}
              taskType={taskType}
            />
            <p className="modal-note">
              <a href={assistanceRequest.request.link} target="_blank" rel="noopener noreferrer">
                {assistanceRequest.request.resourceName}
              </a>
              <br />
              {reviewMessage}
            </p>
            <TaskReviewForm
              onSubmit={nextForm}
              assistanceRequest={assistanceRequest}
              prevForm={backFn}
              answers={answers}
              taskType={taskType}
            />
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default TaskReviewModal;
