import TimeAgoWithTooltip from "Components/TimeAgoWithTooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Avatar from "Components/Avatar";
import Badge from "react-bootstrap/Badge";
import "./TaskMasterInfo.scss";

const determineAvatarImage = taskType => {
  switch (taskType) {
    case "Other":
      return "https://assets.lighthouselabs.ca/logos/rudder-logo.png";
    case "Larry Review":
      return "https://assets.lighthouselabs.ca/logos/larry.png";
    case "AIF Review":
      return "https://assets.lighthouselabs.ca/logos/compass-solid.png";
    case "Curriculum Review":
      return "https://assets.lighthouselabs.ca/logos/shipwright-larry.jpeg";
    default:
      return "https://assets.lighthouselabs.ca/logos/rudder-logo.png";
  }
};

const determineName = taskType => {
  switch (taskType) {
    case "Other":
      return "Rudder Task";
    case "Larry Review":
      return "Larry AI Bot";
    case "AIF Review":
      return "Compass AIF Feedback";
    case "Curriculum Review":
      return "Shipwright Larry";
    default:
      return "Rudder Task";
  }
};

const TaskMasterInfo = ({ when, tags, taskType }) => {
  const name = determineName(taskType);
  return (
    <div className="task-master-container">
      <Avatar src={determineAvatarImage(taskType)} name={name} />
      <div className="task-master-info">
        <div className="name">
          <div className="title">{name}</div>
          <br />
        </div>
        <div className="user-details-container">
          <div className="details">
            {tags.map(tag => (
              <OverlayTrigger
                key={`tagbadge-${tag.name}`}
                placement="bottom"
                overlay={<Tooltip>{tag.queueNote || tag.name}</Tooltip>}
              >
                <Badge
                  className="ml-1 rudder-tag"
                  bg={tag.settings?.badge?.background || "warning"}
                  text={tag.settings?.badge?.text || "dark"}
                >
                  {tag.name}
                </Badge>
              </OverlayTrigger>
            ))}
          </div>
          {when && <TimeAgoWithTooltip when={when} />}
        </div>
      </div>
    </div>
  );
};

export default TaskMasterInfo;
