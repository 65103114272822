const routerDescriptions = {
  max_queue_size: {
    description:
      "The router will try to keep mentor's concurrently assigned ARs within this limit.",
    editable: true,
  },
  desired_task_assignment: {
    description:
      "The router will try to assign an assistance request to this many mentors per scoring round.",
    editable: true,
  },
  max_queue_weight: {
    description: "Avoid assigning tasks to mentors with a max queue.",
    editable: true,
  },
  max_queue_per_minute_adjustment: {
    description:
      "Adjusts Max Queue scores by a decimal percentage each minute, based on age of AR being scored.",
    editable: true,
  },
  pending_task_weight: {
    description: "Avoid assigning tasks to mentors with tasks in their queue.",
    editable: true,
  },
  pending_task_per_minute_adjustment: {
    description:
      "Adjusts Pending Task scores by a decimal percentage each minute, based on age of AR being scored.",
    editable: true,
  },
  active_assistance_weight: {
    description: "Avoid assigning tasks to mentors with active assistances.",
    editable: true,
  },
  active_assistance_per_minute_adjustment: {
    description:
      "Adjusts Active Assistance scores by a decimal percentage each minute, based on age of AR being scored.",
    editable: true,
  },
  active_code_review_weight: {
    description: "Avoid assigning tasks to mentors with active code reviews.",
    editable: true,
  },
  active_code_review_per_minute_adjustment: {
    description:
      "Adjusts Active Code Review scores by a decimal percentage each minute, based on age of AR being scored.",
    editable: true,
  },
  active_evaluation_weight: {
    description: "Avoid assigning tasks to mentors with active evaluations.",
    editable: true,
  },
  active_evaluation_per_minute_adjustment: {
    description:
      "Adjusts Active Evaluation scores by a decimal percentage each minute, based on age of AR being scored.",
    editable: true,
  },
  active_tech_interview_weight: {
    description: "Avoid assigning tasks to mentors with active TI's.",
    editable: true,
  },
  active_tech_interview_per_minute_adjustment: {
    description:
      "Adjusts Active Tech Interview scores by a decimal percentage each minute, based on age of AR being scored.",
    editable: true,
  },
  same_location_weight: {
    description: "Prioritize assigning tasks to mentors in the same location.",
    editable: true,
  },
  same_location_per_minute_adjustment: {
    description:
      "Adjusts Same Location scores by a decimal percentage each minute, based on age of AR being scored.",
    editable: true,
  },
  average_rating_weight: {
    description: "Prioritize assigning tasks to mentors with higher avg feedback ratings.",
    editable: true,
  },
  average_rating_per_minute_adjustment: {
    description:
      "Adjusts Average Rating scores by a decimal percentage each minute, based on age of AR being scored.",
    editable: true,
  },
  positive_assistance_weight: {
    description:
      "Prioritize assigning tasks to mentors who have good assistances with the requestor.",
    editable: true,
  },
  positive_assistance_per_minute_adjustment: {
    description:
      "Adjusts Positive Assistance scores by a decimal percentage each minute, based on age of AR being scored.",
    editable: true,
  },
  negative_assistance_weight: {
    description: "Avoid assigning tasks to mentors who have poor assistances with the requestor.",
    editable: true,
  },
  negative_assistance_per_minute_adjustment: {
    description:
      "Adjusts Negative Assistance scores by a decimal percentage each minute, based on age of AR being scored.",
    editable: true,
  },
  tag_per_minute_adjustment: {
    description:
      "Adjusts all tag scores by a decimal percentage each minute, based on age of AR being scored.",
    editable: true,
  },
};

const featureDescriptions = {
  admin_on_duty: {
    description: "Admin accounts can go on and off duty",
    editable: true,
  },
  admin_request_assignment: {
    description: "Admin accounts can be assigned assistance requests",
    editable: true,
  },
  async_assistance_requests: {
    description: "Requests are made and addressed asyncronously, used in IRCC Rudder instances",
    editable: false,
  },
  auto_conferences: {
    description: "Rudder automatically creates Zoom conferences",
    editable: false,
  },
  chat_assistance: {
    description: "Assistance requests are routed to a Discord channel for chat-based assistances",
    editable: true,
  },
  claimed_ar_cap: {
    description:
      "The maximum number of assistance requests a mentor can have in progress (claimed or started) at a given time",
    editable: true,
  },
  claimed_str_cap: {
    description:
      "The maximum number of secondary task requests a mentor can have in progress at a given time",
    editable: true,
  },
  company_questions: {
    description: "Added company questions for pre-queue requests",
    editable: false,
  },
  concurrent_requests_cap: {
    description:
      "The maximum number of assistance requests a student can have in progress at a time (does not include TIs or Evaluations)",
    editable: true,
  },
  filter_students_by_last_requested_at: {
    description:
      "Only show students last requested in a certain period, used to limit the number of students in the students menu in the queue",
    editable: false,
  },
  hangouts_support: {
    description: "Allows support for Google Hangouts video conferences",
    editable: true,
  },
  jitsi_support: {
    description: "Allows support for Jitsi video conferences",
    editable: true,
  },
  learner_feedback_reminder_email_delay_minutes: {
    description:
      "Number of minutes after a completed request that Rudder waits before sending the reminder email for learner feedback (if enabled)",
    editable: false,
  },
  learner_feedback_reminder_email: {
    description:
      "Sends a learner an email to remind them to provide feedback on their completed request (used for PPS)",
    editable: false,
  },
  learner_selectable_tags_type: {
    description:
      "An older feature where learners could select tags for their requests (no longer in use)",
    editable: false,
  },
  llm_bot: {
    description: "Enables Larry AI Bot in chat assistances",
    editable: true,
  },
  notify_ed_team_flag: {
    description: "Allow mentors to check a box to notify the Ed Delivery Team about an assistance",
    editable: true,
  },
  on_duty: {
    description: "Enables mentors going on or off duty",
    editable: true,
  },
  paginate_form_results: {
    description: "If form results are included in an assistance, paginate them in the queue",
    editable: true,
  },
  piggybacking: {
    description:
      "Allow students to piggyback on each other's assistances (only available for video conferences)",
    editable: false,
  },
  pre_queue_request_minutes: {
    description: "Minutes before the queue opens that a request can be made (used for PPS)",
    editable: false,
  },
  recommend_similar_ars: {
    description:
      "Allows Larry AI Bot to recommend a previous completed assistance request for the same activity with a similar request reason",
    editable: true,
  },
  request_before_queue_opens: {
    description: "Ability for students to make requests before the queue opens (used for PPS)",
    editable: false,
  },
  require_discord_auth: {
    description:
      "Requires that the student links their Rudder account with their Discord account before making an assistance request (required for chat assistances)",
    editable: true,
  },
  secondary_tasks_queue_max: {
    description:
      "The maximum number of secondary tasks shown to a mentor in the Assigned Tasks queue",
    editable: true,
  },
  skip_claim: {
    description: "Assistance Requests skip the claimed state and go right to Start",
    editable: true,
  },
  sort_assigned_requests_by_newest: {
    description: "Sort assigned requests in the queue by newest first (used in IRCC)",
    editable: false,
  },
  suggest_assistances: {
    description:
      "Suggests similar assistance requests (completed or ongoing) when students are making an assistance request",
    editable: true,
  },
  suggest_solved_assistances: {
    description: "Turn on or off to include solved (completed) assistances in the suggestions",
    editable: true,
  },
  update_calendly_events: {
    description: "Show Calendly meeting details in the assistance in the queue",
    editable: true,
  },
  zoom_oauth_support: {
    description: "Enables support for Zoom authorization using OAuth",
    editable: false,
  },
};

const uiDescriptions = {
  assistance_request_modal_title: {
    description:
      "The title of the modal the student uses to request assistance. Default for LHl is 'Request Assistance'",
    editable: true,
  },
  dashboard_note: {
    description: "Note displayed on the dashboard",
    editable: false,
  },
  display_admin_open_requests: {
    description: "Show the Open Requests section in the Admin Queue",
    editable: true,
  },
  display_admin_students_list: {
    description: "Show a list of students in the Admin Queue",
    editable: true,
  },
  display_home_button: {
    description: "Show the Home button in the navigation bar",
    editable: true,
  },
  display_mentors_list: {
    description: "Display a list of the mentors navigation bar",
    editable: true,
  },
  display_open_requests: {
    description: "Display the Open Requests section in the Queue",
    editable: true,
  },
  display_students_by_default: {
    description: "Show all students by default in the Queue",
    editable: true,
  },
  display_students_list: {
    description: "Display a list of students in the Queue",
    editable: true,
  },
  extension_dashboard_note: {
    description: "Messsage included in the dashboard on the Chrome Extension",
    editable: false,
  },
  hide_edit_profile: {
    description: "Hide the edit profile button in the navigation bar",
    editable: true,
  },
  hide_log_issue: {
    description: "Hides the Log Issue button",
    editable: false,
  },
  hide_profile: {
    description: "Hide mentor profiles in the queue",
    editable: true,
  },
  hide_remove_button: {
    description: "Hide the Remove button in the Admin Queue",
    editable: true,
  },
  mentor_queue_note: {
    description:
      "A markdown-friendly message that's displayed to mentors on the regular queue page",
    editable: false,
  },
  notifications_banner_message: {
    description: "The note telling students their notifications are turned off",
    editable: true,
  },
  queue_wait_time: {
    description:
      "Show students their estimated queue wait time and position in the queue when they request assistance",
    editable: true,
  },
  thick_queue_item_dividers: {
    description: "Show extra thicc dividers between assistances in the Queue",
    editable: true,
  },
  zoom_authorization_note: {
    description: "The note sent to users requiring Zoom authorization",
    editable: true,
  },
};

export { routerDescriptions, featureDescriptions, uiDescriptions };
