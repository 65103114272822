import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./ToggleSwitch.scss";

const ToggleSwitch = ({ label, onToggle, checked }) => {
  const [enabled, setEnabled] = useState(checked);

  const handleToggle = () => {
    onToggle();
    setEnabled(!enabled);
  };

  return (
    <label className="switch">
      <input type="checkbox" onClick={handleToggle} checked={enabled} readOnly />
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{label}</Tooltip>}>
        <span className="slider round" />
      </OverlayTrigger>
    </label>
  );
};

export default ToggleSwitch;
