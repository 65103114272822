import { useAppContext } from "Context/AppProvider";
import NavDropdown from "react-bootstrap/NavDropdown";
import ResponsiveLabel from "Components/ResponsiveLabel";
import { Link } from "react-router-dom";

const AdminOptions = () => {
  const { currentUser } = useAppContext();
  const icon = (
    <>
      <i className="fa fa-fw fa-lock" />
      <ResponsiveLabel sizes={["small", "xsmall"]}>Admin</ResponsiveLabel>
    </>
  );

  return (
    <NavDropdown title={icon} id="basic-nav-dropdown" align="end">
      <NavDropdown.Item as={Link} to="/tags">
        Manage Tags
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/users?page=1">
        Manage Users
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/invites">
        Manage Invites
      </NavDropdown.Item>
      {currentUser.isSystemAdmin && (
        <>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/activeadmin">ActiveAdmin</NavDropdown.Item>
        </>
      )}
    </NavDropdown>
  );
};

export default AdminOptions;
