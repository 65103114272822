import ModalBackdrop from "Components/ModalBackdrop";
import SkipRequestForm from "Components/SkipRequestForm";
import { ModalTitle } from "./Typography/Heading";

const SkipRequestModal = ({ requestType, skip, hide }) => {
  const skipRequest = formValues => {
    skip(...Object.values(formValues));
    hide();
  };
  return (
    <ModalBackdrop blur center>
      <div className="modal-dialog" style={{ maxWidth: 700 }}>
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>Skip Assistance Request</ModalTitle>
            <button className="close" onClick={hide} type="button" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <SkipRequestForm requestType={requestType} onSubmit={skipRequest} />
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default SkipRequestModal;
