import { useState } from "react";
import { useAppContext } from "Context/AppProvider";
import FeedbackButton from "Components/FeedbackButton";
import MentorNavigation from "Components/MentorNavigation";
import AssistanceStatusButton from "../Components/AssistanceStatusButton";
import UserOptions from "Components/Navigation/UserOptions";
import AdminOptions from "Components/Navigation/AdminOptions";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import SettingsConditional from "Components/SettingsConditional";
import { storageGet } from "BrowserExtension/utils/storage";
import { isBrowserExtension } from "util/env";
import { useEffect } from "react";

const NavActions = ({ activity, hideActions }) => {
  const {
    queueChannel,
    currentUser,
    teacherHandler: { teachers },
  } = useAppContext();
  const [authStatus, setAuthStatus] = useState();

  const shouldRenderActions = () => {
    if (isBrowserExtension())
      return authStatus === "logged-in" && currentUser.isPresent && !hideActions;

    return currentUser.isPresent && !hideActions;
  };
  const renderNavActions = shouldRenderActions();

  useEffect(() => {
    storageGet("authStatus").then(res => {
      const { authStatus } = res || {};
      if (authStatus) setAuthStatus(authStatus);
    });
  }, []);

  return renderNavActions ? (
    <>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {currentUser.isAdmin && <AdminOptions />}
          {currentUser && <UserOptions currentUser={currentUser} />}
          <SettingsConditional flag={SettingsConditional.MENTORS_LIST} nav teachers={teachers} />
          {currentUser.isTeacher && <MentorNavigation user={currentUser} />}
          {currentUser.isStudent && (
            <>
              <FeedbackButton nav user={currentUser} count={queueChannel.pendingFeedbackCount} />
              <AssistanceStatusButton activity={activity} />
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </>
  ) : (
    <></>
  );
};

export default NavActions;
