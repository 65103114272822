import _ from "lodash";

const RequestQuote = ({ quote, title, length = 200, inline = false }) => {
  if (!quote || _.trim(quote).length === 0) return null;

  const displayTitle = title ? `${title}: ` : "";
  const truncatedQuote = _.truncate(quote, { length: length });

  if (inline) {
    return (
      <span>
        {displayTitle}
        <em>&ldquo;{truncatedQuote}&rdquo;</em>
      </span>
    );
  }

  return (
    <blockquote title={quote}>
      {displayTitle}&ldquo;{truncatedQuote}&rdquo;
    </blockquote>
  );
};

export default RequestQuote;
