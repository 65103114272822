import ModalBackdrop from "Components/ModalBackdrop";
import { ModalTitle } from "Components/Typography/Heading";
import Pronoun from "Components/Pronoun";
import "./AdminQueue.scss";

const DelegateRequestModal = ({ mentors, onClickMentor, hide }) => {
  return (
    <ModalBackdrop blur center>
      <div className="modal-dialog" style={{ maxWidth: 600 }}>
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>Select assignee</ModalTitle>

            <button className="close" onClick={hide} type="button" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="teacher-holder">
              <div className="teacher-image">
                {mentors.map(mentor => (
                  <div className="mentor" key={mentor.id} onClick={() => onClickMentor(mentor.uid)}>
                    <div style={{ width: "min-content" }}>
                      <img alt={mentor.fullName} src={mentor.avatarUrl} />
                    </div>
                    <div className="name">
                      <span className="mr-1">{mentor.fullName}</span>
                      <Pronoun pronoun={mentor.pronoun} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default DelegateRequestModal;
