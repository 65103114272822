// import { useRef, useState } from "react";
import ModalBackdrop from "Components/ModalBackdrop";
import { ModalTitle } from "./Typography/Heading";
// import { useCookies } from "react-cookie";

const IFrameModal = ({ title, src, hide, height }) => {
  // const ref = useRef();
  // const [iFrameHeight, setIFrameHeight] = useState("480px");
  // const [cookies, setCookie, removeCookie] = useCookies(["iframe"]);

  // const onLoad = () => {
  //   setIFrameHeight(
  //     ref.current.contentWindow.document.body.scrollHeight + "px"
  //   );
  // };

  return (
    <ModalBackdrop>
      <div className="modal-dialog" style={{ marginTop: "5rem" }}>
        <div className="modal-content">
          <div className="modal-header">
            {title && <ModalTitle>{title}</ModalTitle>}
            <button type="button" className="close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="ml-3">
              <iframe
                // ref={ref}
                // onLoad={onLoad}
                src={src}
                title={title}
                width="100%"
                // height={iFrameHeight}
                height={height}
                frameBorder="0"
                sandbox="allow-same-origin allow-scripts allow-forms"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default IFrameModal;
