import NavDropdown from "react-bootstrap/NavDropdown";
import ResponsiveLabel from "Components/ResponsiveLabel";
import { Link } from "react-router-dom";
import requests from "Requests";
import { isBrowserExtension } from "util/env";
import { clearExtensionCookiesAndResetStorage } from "BrowserExtension/utils/cookies";
import { useLoadingContext } from "Context/LoadingProvider";
import { withCookies } from "react-cookie";
import { useAppContext } from "Context/AppProvider";

function UserOptions({ currentUser }) {
  const {
    settingsHandler: { settings },
  } = useAppContext();
  const { setIsLoading } = useLoadingContext();

  const signOut = () => {
    setIsLoading(true);
    requests
      .signOut()
      .then(() => {
        if (isBrowserExtension()) {
          clearExtensionCookiesAndResetStorage();
        } else {
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const items = [{ label: `Hi ${currentUser.fullName}`, link: "/profile/view" }];

  if (!settings.ui || (!settings.ui.hideProfile && !settings.ui.hideEditProfile))
    items.push({ label: "Edit Profile", link: "/profile/edit" });

  if (!settings.ui || !settings.ui.hideProfile) items.push({ label: "Sign Out", action: signOut });

  const dropdownItems = items.map(item => {
    return (
      <NavDropdown.Item
        key={item.label}
        as={item.link ? Link : "a"}
        to={item.link}
        onClick={item.action}
      >
        {item.label}
      </NavDropdown.Item>
    );
  });
  const icon = (
    <>
      <i className="fa fa-fw fa-gear" />
      <ResponsiveLabel sizes={["small", "xsmall"]}>User Settings</ResponsiveLabel>
    </>
  );

  return (
    <NavDropdown title={icon} id="basic-nav-dropdown" align="end">
      {dropdownItems}
    </NavDropdown>
  );
}

export default withCookies(UserOptions);
