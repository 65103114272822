import { useEffect, useState } from "react";
import { useAppContext } from "Context/AppProvider";
import Loading from "Screens/Loading";
import Navbar from "Components/bootstrap/Navbar";
import Zoom from "./Zoom";
import Edit from "Screens/users/Edit";
import requests from "Requests";
import { useLoadingContext } from "Context/LoadingProvider";
import "Assets/styles/navbar.scss";
import "Assets/styles/brand.scss";
import "./index.scss";

export const someFieldInvalid = (profileFields, validation) =>
  Object.entries(profileFields).some(([field, required]) => required && !validation(field));

const Requirements = ({ children }) => {
  const [refreshing, setRefreshing] = useState(true);
  const [oAuthTokens, setOAuthTokens] = useState([]);
  const {
    settingsHandler: { isLoading: loading, settings },
    currentUser,
  } = useAppContext();
  const { isLoading, isFirstLoad } = useLoadingContext();

  const userIsPresent = currentUser.isPresent;
  const isInitialState = (isLoading || isFirstLoad) && userIsPresent;

  const getOAuthToken = platform =>
    oAuthTokens.find(t => t.platform.toLowerCase() === platform.toLowerCase());

  // TODO: QUESTION, Is this supposed to run for every user?
  useEffect(() => {
    if (userIsPresent) {
      requests
        .refreshOAuthToken("Zoom")
        .then(oAuthToken => {
          if (oAuthToken) {
            setOAuthTokens([oAuthToken]);
          }
          setRefreshing(false);
        })
        .catch(() => {
          /* do nothing */
        });
    } else {
      setRefreshing(false);
    }
  }, [userIsPresent]);

  // TODO: these requirements need some clarification
  // using variables that describe the whole condition would be an improvement
  const requirements = {
    zoom:
      settings?.requirements?.zoomOauthAppAuthorization &&
      userIsPresent &&
      !currentUser.isAdmin &&
      getOAuthToken("zoom")?.status !== "valid",
    profileFields:
      currentUser.isPresent &&
      someFieldInvalid(settings?.requirements?.userProfileFields || {}, currentUser.hasField),
  };

  const block = Object.values(requirements).some(c => c);

  return refreshing || loading || isInitialState ? (
    <Loading />
  ) : block ? (
    <>
      <Navbar />
      {requirements.profileFields ? (
        <Edit initialSetup />
      ) : (
        <div className="content">
          <div className="requirement">
            <h1>Action Required</h1>
            {requirements.zoom ? <Zoom token={getOAuthToken("zoom")} /> : null}
          </div>
        </div>
      )}
    </>
  ) : (
    children
  );
};

export default Requirements;
