import { Link } from "react-router-dom";
import { useAppContext } from "Context/AppProvider";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ToggleSwitch from "Components/bootstrap/ToggleSwitch";
import SettingsConditional from "Components/SettingsConditional";

const QueueMenu = ({ selectedTeacher }) => {
  const {
    queueChannel: { connected },
    currentUser,
  } = useAppContext();
  const queueName = selectedTeacher ? `${selectedTeacher.firstName}'s queue` : "the admin queue";

  return (
    <div className="queue-header">
      <div className="navigation">
        <h2 className="queue-title">{queueName}</h2>
        {!connected && <i className="fa-solid fa-spinner text-primary queue-loader" />}
        {currentUser.isAdmin && <DeploymentSettingsLink />}
        <SettingsConditional flag={SettingsConditional.SECONDARY_TASK_QUEUE}>
          {currentUser.isAdmin && <TaskCreationFormLink />}
        </SettingsConditional>
      </div>

      <div className="actions">
        {currentUser.isAdmin && (
          <Link to="/queue">
            <ToggleSwitch label="Toggle Admin Queue" checked />
          </Link>
        )}
        <span className="spacer"></span>
        <SettingsConditional
          flag={SettingsConditional.ADMIN_ON_DUTY}
          user={selectedTeacher}
          disabled={!selectedTeacher}
          admin="true"
        />
      </div>
    </div>
  );
};

const DeploymentSettingsLink = () => {
  return (
    <Link to="/queue/admin/deployment-settings">
      <OverlayTrigger placement="bottom" overlay={<Tooltip>Edit Deployment Settings</Tooltip>}>
        <i className="fa-solid fa-gears" />
      </OverlayTrigger>
    </Link>
  );
};

const TaskCreationFormLink = () => {
  return (
    <Link to="/queue/admin/task-creation">
      <OverlayTrigger placement="bottom" overlay={<Tooltip>Create a Secondary Task</Tooltip>}>
        <i className="fa-solid fa-square-plus" />
      </OverlayTrigger>
    </Link>
  );
};

export default QueueMenu;
