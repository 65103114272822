import ModalBackdrop from "./ModalBackdrop";
import StudentInfo from "Components/StudentInfo";
import ManualAssistanceForm from "Components/ManualAssistanceForm";
import "Assets/styles/scrollable-modal.scss";
import { useAppContext } from "Context/AppProvider";
import { ModalTitle } from "./Typography/Heading";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "constants/toast";

const ManualAssistanceModal = ({ student, hide }) => {
  const { queueChannel } = useAppContext();

  const logAssistance = formData => {
    queueChannel.provideManualAssistance(student, formData).catch(err => {
      toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
    });
    hide();
  };

  return (
    <ModalBackdrop>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>Log Assistance Manually</ModalTitle>
            <button type="button" className="close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <b>Feedback for student:</b>
              <StudentInfo student={student} when={student.lastAssistedAt} showDetails={true} />
            </div>
            <ManualAssistanceForm student={student} onSubmit={logAssistance} />
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default ManualAssistanceModal;
