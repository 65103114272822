import { DesktopOverlayTrigger } from "../bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import { determineConferenceTypeName, determineConferenceTypeIcon } from "util/styles";

const Main = ({ type = "secondary", text, onClick, disabled }) => {
  return (
    <button className={`btn btn-sm btn-${type} btn-main`} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

const External = ({ text, url, disabled }) => {
  return (
    <Button.Main
      type="primary"
      text={text || "Open"}
      onClick={() => window.open(url)}
      disabled={disabled}
    />
  );
};

const Light = ({ text, onClick, disabled }) => {
  return <Button.Main type="light" text={text} onClick={onClick} disabled={disabled} />;
};

const Warn = ({ text, onClick, disabled }) => {
  return <Button.Main type="warning" text={text} onClick={onClick} disabled={disabled} />;
};

const Success = ({ text, onClick, disabled }) => {
  return <Button.Main type="success" text={text} onClick={onClick} disabled={disabled} />;
};

const Danger = ({ text, onClick, disabled }) => {
  return <Button.Main type="danger" text={text} onClick={onClick} disabled={disabled} />;
};

const Info = ({ text, onClick, disabled }) => {
  return <Button.Main type="info" text={text} onClick={onClick} disabled={disabled} />;
};

const ConferenceButton = ({ url, disabled, type, tooltip, className }) => (
  <DesktopOverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
    <a
      className={`btn btn-sm ${className}`}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      disabled={disabled}
    >
      {determineConferenceTypeIcon(type)} &nbsp; {tooltip}
    </a>
  </DesktopOverlayTrigger>
);

const ConferenceLink = ({ url, disabled, type, title }) => {
  if (type === "DiscordForum") {
    return (
      <>
        <ConferenceButton
          url={url}
          disabled={disabled}
          type={type}
          tooltip="Browser"
          className="btn-info"
        />
        <ConferenceButton
          url={url.replace(/https?:\/\//, "discord://")}
          disabled={disabled}
          type={type}
          tooltip="App"
          className="btn-primary"
        />
      </>
    );
  }

  return (
    <ConferenceButton
      url={url}
      disabled={disabled}
      type={type}
      tooltip={title || determineConferenceTypeName(type)}
      className="btn-primary"
    />
  );
};

const OpenModal = ({ type, onClick, disabled }) => {
  const determineText = type => {
    switch (type) {
      case "code":
        return "View Code";
      case "error":
        return "View Error Message";
      default:
        return "View Details";
    }
  };
  return (
    <Button.Main type="primary" text={determineText(type)} onClick={onClick} disabled={disabled} />
  );
};

const Button = {
  Main,
  External,
  Light,
  Warn,
  Success,
  Danger,
  Info,
  ConferenceLink,
  OpenModal,
};

export default Button;
