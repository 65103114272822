import { useState } from "react";
import QueueItem from "Components/QueueItem";
import StudentInfo from "Components/StudentInfo";
import RequestDetails from "Components/RequestDetails";
import ShowRequestDetailsModal from "Components/ShowRequestDetailsModal";
import Button from "Components/Buttons/AssistanceButtons";
import SettingsConditional from "Components/SettingsConditional";
import FormResults from "Components/FormResults";
import { useAppContext } from "Context/AppProvider";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "constants/toast";
import SkipRequestModal from "Components/SkipRequestModal";
import Requests from "Requests";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const studentFromAR = ar => ({ tags: ar.tags, ...ar.requestor });

const PendingAssistanceRequest = ({ assistanceRequest }) => {
  const {
    queueChannel: { claimAssistance },
    tasksHandler,
    settingsHandler: { settings },
  } = useAppContext();

  const [disabled, setDisabled] = useState(false);
  const [showSkipRequestModal, setShowSkipRequestModal] = useState(false);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [showErrorMessageModal, setShowErrorMessageModal] = useState(false);
  const mentorSkip = settings?.featureFlags?.mentorSkip;
  const thickDividers = settings?.featureFlags?.thickQueueDividers;

  const handleSkipAR = () => setShowSkipRequestModal(true);

  const handleClaimAR = () => {
    setDisabled(true);
    claimAssistance(assistanceRequest)
      .then(() => tasksHandler.claimAssistance(assistanceRequest.id))
      .catch(err => {
        setDisabled(false);
        toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
      });
  };

  const skipRequest = (reason, notes) => {
    setDisabled(true);

    const queueTask = tasksHandler
      .allOpenTasksForUser()
      .find(task => task.assistanceRequest.id === assistanceRequest.id);

    Requests.skipRequest(queueTask.id, reason, notes)
      .then(() => tasksHandler.skipRequest(queueTask.id))
      .catch(err => {
        setDisabled(false);
        toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
      })
      .finally(setDisabled(false));
  };

  const isSinglePendingRequest =
    tasksHandler
      .pendingAssistanceRequests()
      .filter(ar =>
        tasksHandler
          .allOpenTasks()
          .some(task => task.assistanceRequest.id === ar.id && !task.skippedAt)
      ).length === 1;

  const hoverText = (
    <Tooltip id="tooltip">
      <strong>Cannot skip</strong> when you have only one assigned request remaining.
    </Tooltip>
  );

  const buttonClassName = `btn btn-sm ${
    isSinglePendingRequest ? "btn-secondary" : "btn-light btn-main"
  }`;

  const skipButton = (
    <button
      key="skip"
      className={buttonClassName}
      onClick={isSinglePendingRequest || disabled ? null : handleSkipAR}
      disabled={isSinglePendingRequest}
      style={{ pointerEvents: isSinglePendingRequest ? "none" : "auto" }}
    >
      Skip
    </button>
  );

  const renderSkipButton = () =>
    isSinglePendingRequest ? (
      <OverlayTrigger placement="left" overlay={hoverText}>
        <span>{skipButton}</span>
      </OverlayTrigger>
    ) : (
      skipButton
    );

  return (
    <>
      <QueueItem
        label={assistanceRequest.resourceType}
        type="Request"
        disabled={disabled}
        thickDividers={thickDividers}
      >
        <StudentInfo
          student={studentFromAR(assistanceRequest)}
          showDetails={true}
          when={assistanceRequest.startAt}
          tags={assistanceRequest.tags}
          excludeStudentTags
        />
        {assistanceRequest.formResults ? (
          <FormResults
            formName={assistanceRequest.formResults?.form_name}
            results={assistanceRequest.formResults?.results}
          />
        ) : (
          <div className="blurb">
            <RequestDetails
              categoryName={assistanceRequest.categoryName}
              day={assistanceRequest.day}
              activityUrl={assistanceRequest.activityUrl}
              activityTitle={assistanceRequest.activityTitle}
              reason={assistanceRequest.reason}
            />
            {assistanceRequest.requestCode && (
              <Button.OpenModal type="code" onClick={() => setShowCodeModal(true)} />
            )}
            {assistanceRequest.requestErrorMessage && (
              <Button.OpenModal type="error" onClick={() => setShowErrorMessageModal(true)} />
            )}
          </div>
        )}

        <div className="actions float-right">
          {mentorSkip && renderSkipButton()}
          <button
            key="claim"
            className="btn btn-sm btn-warning btn-main"
            onClick={handleClaimAR}
            disabled={disabled}
          >
            <SettingsConditional flag={SettingsConditional.CLAIM_BUTTON_TEXT} />
          </button>
        </div>
      </QueueItem>
      {showSkipRequestModal && (
        <SkipRequestModal
          requestType="request"
          skip={skipRequest}
          hide={() => setShowSkipRequestModal(false)}
        ></SkipRequestModal>
      )}
      {showCodeModal && (
        <ShowRequestDetailsModal
          type="code"
          requestDetails={assistanceRequest.requestCode}
          setShowModal={setShowCodeModal}
        />
      )}

      {showErrorMessageModal && (
        <ShowRequestDetailsModal
          type="error"
          requestDetails={assistanceRequest.requestErrorMessage}
          setShowModal={setShowErrorMessageModal}
        />
      )}
    </>
  );
};

export default PendingAssistanceRequest;
