import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import logo from "../Assets/images/calendly-logo.png";
import "./CalendlyEvent.scss";

export interface CalendlyEventProps {
  error?: string;
  name: string;
  status: string;
  startTime: Date;
}

const CalendlyEvent = ({ error, name, status, startTime }: CalendlyEventProps) => {
  const renderEventDetails = () => {
    return error ? (
      <>
        <Card.Title>Could not load Calendly information</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          Please contact your Rudder provider to set up your Calendly PAT.
        </Card.Subtitle>
      </>
    ) : (
      <>
        <Card.Title>{name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {startTime && new Date(startTime).toLocaleString()}
        </Card.Subtitle>
        <div>
          <Badge bg={status === "active" ? "success" : "danger"}>{status}</Badge>
        </div>
      </>
    );
  };

  return (
    <Card className="calendly-card">
      <Card.Body className="calendly-card-body">
        <img src={logo} alt="Calendly Logo" height="55px" />
        <div className="calendly-details">
          <div className="calendly-left">{renderEventDetails()}</div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CalendlyEvent;
